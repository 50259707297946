import hacettepe from "./images/partners/hacettepeUni-Ankara.png";
import lokmanHekim from "./images/partners/lokmanHekimUni-Ankara.png";
import perugia from "./images/partners/perugiaUni-Perugia.png";
import charles from "./images/partners/charlesUni-Prague.png";

const PartnersBarHome = () => {
  return (
    <div className="absolute bottom-0 left-0 h-32">
      <div className="h-12 w-screen px-16 flex justify-center items-center">
        <img src={hacettepe} alt="hacettepe" className="mx-12 h-20" />
        <img src={perugia} alt="perugia" className="mx-12 h-24" />
        <img src={charles} alt="charles" className="mx-12 h-28" />
        <img src={lokmanHekim} alt="lokmanHekim" className="mx-12 h-24" />
      </div>
    </div>
  );
};

export default PartnersBarHome;
