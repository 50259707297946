import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth, database } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { ref, set } from "firebase/database";

const SignUpForm = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError("Please fill in all required fields");
      return;
    }

    // Check if password and confirm password match
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Check if password meets the minimum length requirement
    if (password.length < 6) {
      setError("Password should be at least 6 characters");
      return;
    }

    setError("");

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      ).catch((error) => {
        const errorMessage = error.message;

        const getErrorMessage = (errorMessage) => {
          if (errorMessage.includes("user-not-found")) {
            return "User not found!";
          }
          if (errorMessage.includes("wrong-password")) {
            return "You entered the wrong password!";
          }
          if (errorMessage.includes("network-request-failed")) {
            return "Please check your internet connection!";
          }
          if (errorMessage.includes("missing-email")) {
            return "Please enter your email address";
          }
          if (errorMessage.includes("invalid-email")) {
            return "Please enter a vaild email address";
          } else {
            return "Something went wrong!";
          }
        };
        setError(getErrorMessage(errorMessage));
      });
      await sendEmailVerification(userCredential.user);
      const user = userCredential.user;

      // Generate a new key for the user's data
      const newUserKey = user.uid;

      // Create a reference to the user's data using the generated key
      const userRef = ref(database, `users/${newUserKey}`);

      // Define the user data
      const userData = {
        id: newUserKey,
        firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
        lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
        email: user.email,
        boughtModels: {
          0: "",
        },
        createdModels: {
          0: "",
        },
        wishlist: {
          0: "",
        },
      };

      // Store the user data in the database
      await set(userRef, userData);

      navigate("/login");
    } catch (error) {
      const errorMessage = error.message;

      const getErrorMessage = (errorMessage) => {
        if (errorMessage.includes("user-not-found")) {
          return "User not found!";
        }
        if (errorMessage.includes("wrong-password")) {
          return "You entered the wrong password!";
        }
        if (errorMessage.includes("network-request-failed")) {
          return "Please check your internet connection!";
        }
        if (errorMessage.includes("missing-email")) {
          return "Please enter your email address";
        }
        if (errorMessage.includes("invalid-email")) {
          return "Please enter a vaild email address";
        } else {
          return "Something went wrong!";
        }
      };
      setError(getErrorMessage(errorMessage));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen max-w-xl mx-auto">
      <div className="w-full p-8 rounded-xl shadow-md shadow-blue-100 bg-blue-100/50">
        <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
          Register
        </h2>
        <form className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="firstName"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter your first name"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter your last name"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-gray-500/75 text-sm font-semibold mb-1"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-gray-500/75 text-sm font-semibold mb-1"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="border-b-2 pb-6 border-blue-800">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-500/75 text-sm font-semibold mb-1"
            >
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              type="password"
              className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          <div className="py-2">
            <button
              type="submit"
              className="w-full bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
          {error && <p className="text-red-600 mt-2">{error}</p>}
          <p className="mt-2 text-sm text-gray-500/75 text-center">
            Already have an account?
            <NavLink
              to="/login"
              className="ml-1 text-blue-700 hover:underline underline-offset-2"
            >
              Please Login
            </NavLink>
            .
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
