import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import MeetingsText from "../components/MeetingsText";

const Meetings = () => {
  return (
    <>
      <Navbar />
      <MeetingsText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default Meetings;
