import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { loginFailure, loginStart, loginSuccess } from "../redux/userRedux";
import { useDispatch, useSelector } from "react-redux";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector((state) => state.user);
  const [handleError, setHandleError] = useState("");
  const [forgotMyPassword, setForgotMyPassword] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetEmailError, setResetEmailError] = useState("");

  const handleSignIn = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      dispatch(loginStart());
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          // Check if the user's email is verified
          if (user.emailVerified) {
            const uid = user.uid;
            dispatch(loginSuccess(uid));
            navigate("/home");
          } else {
            // If email is not verified, display an error message
            dispatch(loginFailure(true));
            setHandleError("Please verify your email before signing in.");
          }
        })
        .catch((error) => {
          // Handle error here
          dispatch(loginFailure(true));
          const errorMessage = error.message;
          const getErrorMessage = (errorMessage) => {
            if (errorMessage.includes("user-not-found")) {
              return "User not found!";
            }
            if (errorMessage.includes("wrong-password")) {
              return "You entered the wrong password!";
            }
            if (errorMessage.includes("network-request-failed")) {
              return "Please check your internet connection!";
            }
            if (errorMessage.includes("missing-email")) {
              return "Please enter your email address";
            }
            if (errorMessage.includes("invalid-email")) {
              return "Please enter a vaild email address";
            } else {
              return "Something went wrong!";
            }
          };
          setHandleError(getErrorMessage(errorMessage));
          setTimeout(() => {
            setHandleError("");
          }, 5000);
        });
    } catch (error) {
      // Handle error here
      dispatch(loginFailure(error.message));
      setHandleError(error.message);
      setTimeout(() => {
        setHandleError("");
      }, 5000);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const email = e.target.resetEmail.value;

    try {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setResetEmailSent(true);
          setResetEmailError("");
          setTimeout(() => {
            setResetEmailSent(false);
            setForgotMyPassword(false);
          }, 5000);
        })
        .catch((error) => {
          const errorMessage = error.message;
          const getErrorMessage = (errorMessage) => {
            if (errorMessage.includes("user-not-found")) {
              return "User not found!";
            }
            if (errorMessage.includes("wrong-password")) {
              return "You entered the wrong password!";
            }
            if (errorMessage.includes("network-request-failed")) {
              return "Please check your internet connection!";
            }
            if (errorMessage.includes("missing-email")) {
              return "Please enter your email address";
            }
            if (errorMessage.includes("invalid-email")) {
              return "Please enter a vaild email address";
            } else {
              return "Something went wrong!";
            }
          };
          setResetEmailError(getErrorMessage(errorMessage));
          setResetEmailSent(false);
          setTimeout(() => {
            setResetEmailError("");
          }, 5000);
        });
    } catch (error) {
      const errorMessage = error.message;
      const getErrorMessage = (errorMessage) => {
        if (errorMessage.includes("user-not-found")) {
          return "User not found!";
        }
        if (errorMessage.includes("wrong-password")) {
          return "You entered the wrong password!";
        }
        if (errorMessage.includes("network-request-failed")) {
          return "Please check your internet connection!";
        }
        if (errorMessage.includes("missing-email")) {
          return "Please enter your email address";
        }
        if (errorMessage.includes("invalid-email")) {
          return "Please enter a vaild email address";
        } else {
          return "Something went wrong!";
        }
      };
      setResetEmailError(getErrorMessage(errorMessage));
      setResetEmailSent(false);
      setTimeout(() => {
        setResetEmailError("");
      }, 5000);
    }

    // navigate success page?
  };

  const goBackToLogin = () => {
    setResetEmailError("");
    setResetEmailSent(false);
    setForgotMyPassword(false);
  };

  const goForgotMyPassword = () => {
    setHandleError("");
    setForgotMyPassword(true);
  };

  const forgotPasswordForm = () => {
    return (
      <div>
        <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
          Reset password
        </h2>
        <form className="space-y-4" onSubmit={handleResetPassword}>
          <div className="border-b-2 pb-6 border-blue-800">
            <label
              htmlFor="resetEmail"
              className="block text-gray-500/75 text-sm font-semibold mb-1"
            >
              Email
            </label>
            <input
              id="resetEmail"
              type="email"
              className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              placeholder="Enter your email"
            />
          </div>

          <div className="py-2">
            <button
              type="submit"
              className="w-full bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold"
            >
              Send Email
            </button>
          </div>
          {resetEmailSent && (
            <p className="text-green-600 mt-1">
              Reset password email sent. Please check your inbox.
            </p>
          )}
          {resetEmailError && (
            <p className="text-red-600 mt-2">{resetEmailError}</p>
          )}

          <p className="mt-2 text-sm text-gray-600 text-center">
            <button
              className="text-blue-700 hover:underline underline-offset-2"
              onClick={goBackToLogin}
            >
              Go to Login page.
            </button>
          </p>
        </form>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen max-w-xl mx-auto">
      <div className="w-full p-8 rounded-xl shadow-md shadow-blue-100 bg-blue-100/50">
        {forgotMyPassword ? (
          forgotPasswordForm()
        ) : (
          <div>
            <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
              Login
            </h2>
            <form className="space-y-4" onSubmit={handleSignIn}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-500/75 text-sm font-semibold mb-1"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                  placeholder="Enter your email"
                />
              </div>
              <div className="border-b-2 pb-6 border-blue-800">
                <label
                  htmlFor="password"
                  className="block text-gray-500/75 text-sm font-semibold mb-1"
                >
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  className="w-full px-3 py-2 mt-1 mb-4 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                  placeholder="Enter your password"
                />
              </div>
              <div className="py-2">
                <button
                  type="submit"
                  className={`w-full bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold ${
                    isFetching ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isFetching}
                >
                  Sign In
                </button>
              </div>
            </form>
            {/* Display error message if there is an error */}
            {error && <p className="text-red-600 mt-2">{handleError}</p>}
            <div className="flex justify-between items-center">
              <p className="mt-2 text-sm text-gray-600 text-center">
                <button
                  className="text-blue-700 hover:underline underline-offset-2"
                  onClick={goForgotMyPassword}
                >
                  Forgot your password?
                </button>
              </p>
              <p className="mt-2 text-sm text-gray-500/75 text-center">
                Not a member?{" "}
                <Link
                  to="/signup"
                  className="ml-1 text-blue-700 hover:underline underline-offset-2"
                >
                  Please Sign Up
                </Link>
                .
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
