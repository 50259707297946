import { child, push, ref, set } from "firebase/database";
import { useState } from "react";
import { database } from "../firebase";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform the necessary action with the form data (e.g., send email, save to database)
    if (!name || !email || !message || !subject) {
      setError("Please fill in all required fields");
      return;
    }

    // generate a unique key for contact form
    const newContactKey = push(child(ref(database), "/contactdata")).key;

    const newForm = {
      subject,
      name,
      email,
      message,
    };

    // push it to the database
    set(ref(database, "/contactdata/" + newContactKey), newForm);

    // Reset the form
    setSubject("");
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="flex justify-center mx-auto max-w-5xl mt-8 mb-4">
      <div className="w-full p-6 border-2 rounded-xl border-slate-200 shadow-md hover:shadow-blue-500 hover:bg-blue-100 bg-blue-100/50">
        <h2 className="text-2xl font-bold mb-4 text-blue-700">CONTACT US</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-500/75 text-sm font-semibold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              type="text"
              id="name"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-500/75 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-500/75 text-sm font-semibold mb-2"
              htmlFor="subject"
            >
              Subject
            </label>
            <input
              className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              type="text"
              id="subject"
              placeholder="Enter the subject"
              value={subject}
              onChange={handleSubjectChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-500/75 text-sm font-semibold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600 resize-none h-20"
              id="message"
              placeholder="Enter your message"
              value={message}
              onChange={handleMessageChange}
            ></textarea>
          </div>
          {error && <p className="text-red-600 mt-2">{error}</p>}
          <div className="flex justify-center">
            <button
              className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-semibold"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
