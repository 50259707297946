import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { gsap } from "gsap";

import UserDropdownHome from "./UserDropdownHome";

/* import logo from "./images/logo.png"; 
import Badge from "@mui/material/Badge";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"; */

const Navbar = () => {
  const navigate = useNavigate(); /* 
  const quantity = useSelector((state) => state.cart.quantity); */
  const isSignedIn = useSelector((state) => state.user.isSignedIn);

  useEffect(() => {
    // Fade-in animation
    gsap.from(".nav-fade-in", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });
  }, []);

  return (
    <div className="relative">
      <nav className="absolute top-0 left-0 w-full z-20 nav-fade-in bg-opacity-40 bg-black">
        <div className="flex items-center justify-between h-20 px-16">
          {/* Logo */}
          {/* <div className="flex-shrink-0">
            <button className="mt-2" onClick={() => navigate("/home")}>
              <img
                src={logo}
                alt="logo"
                className="h-24 amt-16 w-auto object-contain"
              />
            </button>
          </div> */}
          {/* Header */}
          <div className="flex justify-center">
            <span className="text-slate-50 text-lg font-semibold">
              EUROPEAN YOUTH 3D ANATOMICAL MODELING PLATFORM
            </span>
          </div>
          {/* Sign In, Login, Cart */}
          <div className="flex items-center space-x-4">
            {isSignedIn ? (
              <UserDropdownHome />
            ) : (
              <>
                <button
                  className="hover:text-sky-400 text-slate-200 px-4 py-2 rounded-md text-sm font-medium shadow"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <button
                  className="hover:text-sky-400 text-slate-200 px-4 py-2 rounded-md text-sm font-medium shadow"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </button>
              </>
            )}
            {/* <Link
              to="/cart"
              className="hover:text-green-500 text-slate-200 px-4 py-2 rounded-md text-sm font-medium shadow"
            >
              <Badge badgeContent={quantity} max={99} color="primary">
                <ShoppingCartOutlinedIcon />
              </Badge>
            </Link> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
