import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

import en from "./images/flags/gb.png";
import tr from "./images/flags/tr.png";
import cz from "./images/flags/cz.png";
import it from "./images/flags/it.png";

const WhatIs = () => {
  const [language, setLanguage] = useState("EN");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const languages = ["EN", "TR", "IT", "CZ"];
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const flags = {
    EN: en,
    TR: tr,
    CZ: cz,
    IT: it,
  };

  const texts = {
    EN: {
      title: "What is EYOU3DAMP?",
      content: [
        "EYOU3DAMP is an Erasmus+ KA220YOU project funded by EU and National Agency of Türkiye.",
        "The main aim of EYOU3DAMP is to provide a multicentered, multidisciplinary platform of standard / personalized, virtual / physical 3D (dimensional) anatomical models in 3 countries that have adopted different education ecoles in Europe (Turkey, Italy and Czech Republic) and 4 universities (Hacettepe, Perugia and Charles universities - State, Lokman Hekim University - Private) and create a supportive and complementary materials that can be used in basic / clinical and surgical Anatomy training with these developed models.",
        "The participation of young people, who are interested in this field and working (can work) in different sectors, together with the academic partners of the project by producing their models on this platform and examining the adequacy and suitability of the models in the presence of academic advisors is also one of the important goals for the project.",
        "We have three project results/intellectual outcomes, which are formation and production of 3D Models, implementation of 3D Modelsinto digital platform and assessment and evaluation of 3D Models andPlatform. With these outcomes, we aim to stimulate innovative learningand teaching practices by developing learning outcomes andstudent-centered curricula that better meet the learning needs ofstudents and reduce skills mismatches, while also being relevant forthe labor market and for the wider society.",
      ],
    },
    TR: {
      title: "EYOU3DAMP nedir?",
      content: [
        "EYOU3DAMP, AB ve Türkiye Ulusal Ajansı tarafından finanse edilen bir Erasmus+ KA220YOU projesidir.",
        "EYOU3DAMP'ın temel amacı, Avrupa'da farklı eğitim ekollerini benimsemiş 3 ülke (Türkiye, İtalya ve Çek Cumhuriyeti) ve 4 üniversitede (Türkiye, İtalya ve Çek Cumhuriyeti) standart/kişiselleştirilmiş, sanal/fiziksel 3D (boyutsal) anatomik modellerin çok merkezli, çok disiplinli bir platformunu sağlamaktır. Hacettepe, Perugia ve Charles üniversiteleri - Devlet, Lokman Hekim Üniversitesi - Özel) ve geliştirilen bu modeller ile temel/klinik ve cerrahi Anatomi eğitimlerinde kullanılabilecek destekleyici ve tamamlayıcı materyaller oluşturulmaktadır.",
        "Bu alana ilgi duyan ve farklı sektörlerde çalışan (çalışabilen) gençlerin projenin akademik ortaklarıyla birlikte bu platformda modellerini üreterek katılımları, modellerin yeterliliği ve uygunluğunun akademik danışmanlar ve uzmanlar eşliğinde incelenmesi projenin önemli hedeflerindendir.",
        "3D Modellerin oluşturulması ve üretilmesi, 3D Modellerin dijital platforma uygulanması ve 3D Modellerin ve Platformun değerlendirilmesi ve değerlendirilmesi olmak üzere üç proje sonucumuz/fikri çıktımız var. Bu sonuçlarla, öğrencilerin öğrenme ihtiyaçlarını daha iyi karşılayan ve beceri uyumsuzluklarını azaltan, aynı zamanda işgücü piyasasına ve daha geniş anlamda topluma uygun öğrenme çıktıları ve öğrenci merkezli müfredatlar geliştirerek yenilikçi öğrenme ve öğretme uygulamalarını teşvik etmeyi amaçlıyoruz.",
      ],
    },
    IT: {
      title: "Cos'è EYOU3DAMP?",
      content: [
        "EYOU3DAMP è un progetto Erasmus+ KA220YOU finanziato dall'UE e dall'Agenzia nazionale della Turchia.",
        "Lo scopo principale di EYOU3DAMP è quello di fornire una piattaforma multicentrica e multidisciplinare di modelli anatomici 3D (dimensionali) standard/personalizzati, virtuali/fisici in 3 paesi che hanno adottato diverse scuole di istruzione in Europa (Turchia, Italia e Repubblica Ceca) e 4 università ( Università Hacettepe, Perugia e Charles - Statale, Università Lokman Hekim - Privata) e creare materiali di supporto e complementari che possono essere utilizzati nella formazione di anatomia di base/clinica e chirurgica con questi modelli sviluppati.",
        "La partecipazione di giovani interessati a questo campo e che lavorano (possono lavorare) in diversi settori, insieme ai partner accademici del progetto producendo i loro modelli su questa piattaforma ed esaminando l'adeguatezza e l'idoneità dei modelli in presenza di consulenti accademici è anche uno degli obiettivi importanti del progetto.",
        "Abbiamo tre risultati del progetto/risultati intellettuali, che sono la formazione e la produzione di modelli 3D, l'implementazione di modelli 3D nella piattaforma digitale e la valutazione di modelli 3D e piattaforma. Con questi risultati, miriamo a stimolare pratiche di apprendimento e insegnamento innovative sviluppando risultati di apprendimento e programmi di studio incentrati sullo studente che soddisfino meglio le esigenze di apprendimento degli studenti e riducano gli squilibri tra domanda e offerta di competenze, pur essendo rilevanti per il mercato del lavoro e per la società in generale.",
      ],
    },
    CZ: {
      title: "Co je EYOU3DAMP?",
      content: [
        "EYOU3DAMP je projekt Erasmus+ KA220YOU financovaný EU a národní agenturou Türkiye.",
        "Hlavním cílem EYOU3DAMP je poskytovat multicentrickou, multidisciplinární platformu standardních / personalizovaných, virtuálních / fyzických 3D (dimenzionálních) anatomických modelů ve 3 zemích, které přijaly různé vzdělávací ecoles v Evropě (Turecko, Itálie a Česká republika) a 4 univerzitách ( Univerzity Hacettepe, Perugia a Karlovy – státní, Univerzita Lokmana Hekima – soukromá) a vytvořit podpůrné a doplňkové materiály, které lze s těmito vyvinutými modely použít v základním / klinickém a chirurgickém tréninku anatomie.",
        "Účast mladých lidí, kteří se o tuto oblast zajímají a pracují (mohou pracovat) v různých sektorech, spolu s akademickými partnery projektu vytvářením svých modelů na této platformě a zkoumáním vhodnosti a vhodnosti modelů za přítomnosti akademičtí poradci je také jedním z důležitých cílů projektu.",
        "Máme tři výsledky projektu/intelektuální výstupy, kterými jsou tvorba a výroba 3D modelů, implementace 3D modelů do digitální platformy a posouzení a vyhodnocení 3D modelů a platformy. S těmito výsledky se snažíme stimulovat inovativní postupy učení a výuky prostřednictvím rozvoje výsledků učení a učebních osnov zaměřených na studenty, které lépe odpovídají vzdělávacím potřebám studentů a snižují nesoulad mezi nabízenými dovednostmi a zároveň jsou relevantní pro trh práce a pro širší společnost.",
      ],
    },
  };

  useEffect(() => {
    // Fade-in animation for the entire component
    gsap.from(".fade-in", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });

    // Slide-up animation for the text
    gsap.from(".text-animate", {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      delay: 1,
    });

    // Add event listener to detect clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openDropdown = () => {
    setIsDropdownOpen(true);
    gsap.fromTo(
      ".language-option",
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
    );
  };

  const closeDropdown = () => {
    gsap.to(".language-option", {
      x: -50,
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
      onComplete: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const handleLanguageChange = (lang) => {
    closeDropdown();
    gsap.to(dropdownRef.current, {
      onComplete: () => {
        setLanguage(lang);
        gsap.fromTo(
          contentRef.current,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 1 }
        );
      },
    });
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-blue-950 flex items-center justify-center fade-in">
      <div className="w-6xl h-screen py-20 px-32 bg-slate-50">
        <div className="relative inline-block text-left mb-4" ref={dropdownRef}>
          <div className="flex items-center">
            <button
              onClick={() =>
                isDropdownOpen ? closeDropdown() : openDropdown()
              }
              className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span className="mr-2">
                <img
                  src={flags[language]}
                  alt={language}
                  className="h-5 w-7 inline-block"
                />
              </span>
              {language}
            </button>
            <div
              className={`flex items-center ${
                isDropdownOpen ? "ml-2" : "hidden"
              }`}
            >
              {languages
                .filter((lang) => lang !== language)
                .map((lang) => (
                  <button
                    key={lang}
                    onClick={() => handleLanguageChange(lang)}
                    className="language-option flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <img
                      src={flags[lang]}
                      alt={lang}
                      className="h-5 w-7 mr-2"
                    />
                    {lang}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div ref={contentRef} className="w-full max-w-4xl">
          <h1 className="text-5xl font-bold mb-4 text-animate">
            {texts[language].title}
          </h1>
          <div className="text-xl my-10 text-animate">
            {texts[language].content.map((paragraph, index) => (
              <p key={index} className="mb-4">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIs;
