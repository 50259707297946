import image1 from "./images/icon1.png";
import image2 from "./images/icon2.png";
import image3 from "./images/icon3.png";

const Info = () => {
  return (
    <div className="flex justify-center w-full my-20">
      <div className="w-1/4 border rounded-xl border-slate-200 shadow-md hover:shadow-blue-500 hover:bg-blue-100 bg-blue-100/50 p-4 mr-2">
        <img src={image1} alt="alt 1" className="w-2/3 mx-auto" />
        <h3 className="text-xl font-black mt-8 text-center text-blue-800">
          ANATOMY MODELLING
        </h3>
        <p className="text-gray-600 text-center mt-4 mx-1 mb-2">
          Anatomy modelling is an integral component of the EYOU3DAMP project,
          focusing on the development of detailed and accurate three-dimensional
          (3D) models of human anatomy. These models will be constructed using
          cutting-edge technologies, such as laser scanners and specialized
          software. The project will leverage radiological images, open-source
          model databases, and photogrammetry to generate these virtual models.
          One of the project's key objectives is to empower young individuals
          interested in health professions and engineering sciences by providing
          them with the skills and knowledge needed to create and use these 3D
          models. By offering hands-on experience in the creation of 3D
          anatomical models, EYOU3DAMP fosters employability and
          entrepreneurship in the rapidly growing field of 3D modelling and its
          related sectors.
        </p>
      </div>
      <div className="w-1/4 border rounded-xl border-slate-200 shadow-md hover:shadow-blue-500 hover:bg-blue-100 bg-blue-100/50 p-4 mx-2">
        <img src={image2} alt="alt 2" className="w-2/3 mx-auto" />
        <h3 className="text-xl font-black mt-8 text-center text-blue-800">
          SIMULATION
        </h3>
        <p className="text-gray-600 text-center mt-4 mx-1 mb-2">
          EYOU3DAMP acknowledges the significance of simulation in modern
          medical education and training. To this end, the project aims to
          integrate the created 3D models into digital learning platforms,
          transforming them into interactive simulations. The utilization of
          software such as Skatchfab, Moodle, and Blackboard will allow the
          dissemination of these simulations, providing a multidimensional and
          immersive learning environment. This approach will enable students and
          young professionals to study complex anatomical structures and
          procedures in a risk-free, virtual environment, fostering a more
          profound understanding and mastery of human anatomy.
        </p>
      </div>
      <div className="w-1/4 border rounded-xl border-slate-200 shadow-md hover:shadow-blue-500 hover:bg-blue-100 bg-blue-100/50 p-4 ml-2">
        <img src={image3} alt="alt 3" className="w-2/3 mx-auto" />
        <h3 className="text-xl font-black mt-8 text-center text-blue-800">
          GAMIFICATION
        </h3>
        <p className="text-gray-600 text-center mt-4 mx-1 mb-2">
          Recognizing the power of gamification in enhancing learning outcomes,
          EYOU3DAMP strives to apply game design principles to the field of
          anatomy education. By incorporating 3D models and simulations into a
          gaming context, the project offers a highly engaging, interactive, and
          innovative approach to learning. This approach not only stimulates
          interest but also promotes active participation and self-directed
          learning. Through serious gaming and gamification, EYOU3DAMP aspires
          to make the study of human anatomy more accessible and enjoyable,
          thereby improving knowledge retention and application. Additionally,
          through game analytics, the project aims to provide personalized
          feedback and adapt learning experiences to individual needs, fostering
          a more effective and tailored learning process.
        </p>
      </div>
    </div>
  );
};

export default Info;
