import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import MultiplierEventText from "../components/MultiplierEventText";

const MultiplierEvent = () => {
  return (
    <>
      <Navbar />
      <MultiplierEventText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default MultiplierEvent;
