const Footer = () => {
  return (
    <footer className="bg-blue-800 py-6">
      <div className="container mx-auto text-center">
        <p className="text-slate-50 text-lg">
          Copyright © 2023 EYOU3DAMP. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
