import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    models: [],
    quantity: 0,
    total: 0,
  },
  reducers: {
    addModel: (state, action) => {
      state.quantity += 1;
      state.models.push(action.payload);
      state.total += action.payload.price;
    },
    removeModel: (state, action) => {
      const index = state.models.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.models.splice(index, 1);
        state.quantity -= 1;
        state.total -= action.payload.price;
      }
    },
    clearCart: (state) => {
      state.models = [];
      state.quantity = 0;
      state.total = 0;
    },
  },
});

export const { addModel, removeModel, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
