import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

import me from "./images/meetings/me.jpeg";
import en from "./images/flags/gb.png";
import tr from "./images/flags/tr.png";
import cz from "./images/flags/cz.png";
import it from "./images/flags/it.png";

const MultiplierEventText = () => {
  const [language, setLanguage] = useState("EN");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const languages = ["EN", "TR", "IT", "CZ"];
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const flags = {
    EN: en,
    TR: tr,
    CZ: cz,
    IT: it,
  };

  const texts = {
    EN: {
      venueTitle: "Country of Venue:",
      dateTitle: "Date:",
      leadingOrganizationTitle: "Leading Organization:",
      participatingOrganizationsTitle: "Participating Organizations:",
      venue: "Türkiye",
      date: "16-18 April 2024",
      description: `The main objective of this event is dissemination of results and intellectual outcomes of the project most effectively and indirectly to the public. Target groups are anatomists, students of medical school and anatomy related sciences, health engineering sciences professionals, surgeons related with surgical anatomy, other health professionals including nurses, occupational therapists, physical therapists and sector representatives. In the near past, Hacettepe University, which is a leading organization of this proposal, recently conducted 'EuroSOMT' and previously completed 'MedTRain3DModSim' Erasmus+ Higher Education Projects, completed over 5 international congresses, symposiums and forums about 3D Modelling & Simulation including First (2013) and Second (2015) International UroAnatomy&UroTechnology&UroSimulation Congresses, Medical Innovation Forum (2015), International Symposium of Medical 3D Modelling Standardization (2016), International 3D Medical Printing & Simulation Congress (2018). In this multiplier dissemination event, with experts and participants from different sectors and disciplines; 3D modeling technologies, implementation of 3D models in platforms and simulators, the usage of these models and simulators in basic and clinical anatomy training, surgical planning and patient education will be discussed and new solution proposals will be created.`,
      leadingOrganization: "Hacettepe Üniversitesi",
      participatingOrganizations: [
        "Universita Degli Studi di Perugia",
        "Lokman Hekim Üniversitesi",
        "Univerzita Karlova",
      ],
    },
    TR: {
      venueTitle: "Mekan Ülkesi:",
      dateTitle: "Tarih:",
      leadingOrganizationTitle: "Düzenleyen Kuruluş:",
      participatingOrganizationsTitle: "Katılımcı Kuruluşlar:",
      venue: "Türkiye",
      date: "16-18 Nisan 2024",
      description: `Bu etkinliğin temel amacı, projenin sonuçlarının ve fikri çıktılarının en etkili ve dolaylı şekilde kamuoyuna duyurulmasıdır. Hedef gruplar anatomistler, tıp fakültesi ve anatomi ile ilgili bilimler öğrencileri, sağlık mühendisliği bilimleri profesyonelleri, cerrahi anatomi ile ilgili cerrahlar, hemşireler, ergoterapistler, fizyoterapistler ve sektör temsilcileri dahil olmak üzere diğer sağlık profesyonelleridir. Yakın geçmişte, bu önerinin öncü kuruluşlarından biri olan Hacettepe Üniversitesi, yakın zamanda 'EuroSOMT'u yürütmüş ve daha önce 'MedTRain3DModSim' Erasmus+ Yüksek Öğrenim Projelerini tamamlamış, Birinci (2013) ve İkinci (2015) Uluslararası ÜroAnatomi&ÜroTeknoloji&ÜroSimülasyon Kongreleri, Tıbbi İnovasyon Forumu (2015), Uluslararası Tıbbi 3D Modelleme Standardizasyonu Sempozyumu (2016), Uluslararası 3D Tıbbi Baskı ve Simülasyon Kongresi (2018) dahil olmak üzere 3D Modelleme ve Simülasyon hakkında 5'ten fazla uluslararası kongre, sempozyum ve forumu tamamlamıştır. Bu çarpan yayılım etkinliğinde, farklı sektörlerden ve disiplinlerden uzmanlar ve katılımcılarla; 3D modelleme teknolojileri, 3D modellerin platformlarda ve simülatörlerde uygulanması, bu modellerin ve simülatörlerin temel ve klinik anatomi eğitiminde kullanımı, cerrahi planlama ve hasta eğitimi tartışılacak ve yeni çözüm önerileri oluşturulacaktır.`,
      leadingOrganization: "Hacettepe Üniversitesi",
      participatingOrganizations: [
        "Universita Degli Studi di Perugia",
        "Lokman Hekim Üniversitesi",
        "Univerzita Karlova",
      ],
    },
    IT: {
      venueTitle: "Paese di sede:",
      dateTitle: "Data:",
      leadingOrganizationTitle: "Organizzazione capofila:",
      participatingOrganizationsTitle: "Organizzazioni partecipanti:",
      venue: "Turchia",
      date: "16-18 aprile 2024",
      description: `L'obiettivo principale di questo evento è la diffusione dei risultati e degli esiti intellettuali del progetto nel modo più efficace e indiretto al pubblico. I gruppi target sono anatomisti, studenti di medicina e scienze correlate all'anatomia, professionisti delle scienze ingegneristiche sanitarie, chirurghi correlati all'anatomia chirurgica, altri professionisti sanitari tra cui infermieri, terapisti occupazionali, fisioterapisti e rappresentanti del settore. Nel recente passato, l'Università di Hacettepe, che è un'organizzazione leader di questa proposta, ha recentemente condotto 'EuroSOMT' e in precedenza completato 'MedTRain3DModSim' Erasmus+ Higher Education Projects, ha completato oltre 5 congressi internazionali, simposi e forum sulla modellazione e simulazione 3D, tra cui il primo (2013) e il secondo (2015) congresso internazionale di UroAnatomy&UroTechnology&UroSimulation, il Medical Innovation Forum (2015), l'International Symposium of Medical 3D Modelling Standardization (2016), l'International 3D Medical Printing & Simulation Congress (2018). In questo evento di disseminazione moltiplicatore, con esperti e partecipanti provenienti da diversi settori e discipline; le tecnologie di modellazione 3D, l'implementazione di modelli 3D in piattaforme e simulatori, l'uso di questi modelli e simulatori nella formazione di base e clinica in anatomia, pianificazione chirurgica e istruzione del paziente saranno discussi e saranno create nuove proposte di soluzioni.`,
      leadingOrganization: "Hacettepe Üniversitesi",
      participatingOrganizations: [
        "Universita Degli Studi di Perugia",
        "Lokman Hekim Üniversitesi",
        "Univerzita Karlova",
      ],
    },
    CZ: {
      venueTitle: "Země konání:",
      dateTitle: "Datum:",
      leadingOrganizationTitle: "Vedoucí organizace:",
      participatingOrganizationsTitle: "Zúčastněné organizace:",
      venue: "Türkiye",
      date: "16-18 dubna 2024",
      description: `Hlavním cílem této akce je co nejefektivněji a nepřímo šířit výsledky a intelektuální výstupy projektu mezi veřejnost. Cílovými skupinami jsou anatomové, studenti lékařských fakult a věd příbuzných anatomii, odborníci z oblasti zdravotnického inženýrství, chirurgové související s chirurgickou anatomií, další zdravotníci včetně sester, ergoterapeuti, fyzioterapeuti a zástupci sektorů. V blízké minulosti Hacettepe University, která je vedoucí organizací tohoto návrhu, nedávno provedla 'EuroSOMT' a dříve dokončila 'MedTRain3DModSim' Erasmus+ vysokoškolské projekty, dokončila více než 5 mezinárodních kongresů, sympozií a fór o 3D modelování a simulaci, včetně First ( 2013) a druhý (2015) mezinárodní kongresy UroAnatomy&UroTechnology&UroSimulation, Medical Innovation Forum (2015), International Symposium of Medical 3D Modeling Standardization (2016), International 3D Medical Printing & Simulation Congress (2018). V této multiplikační akci s odborníky a účastníky z různých odvětví a oborů; Budou diskutovány technologie 3D modelování, implementace 3D modelů do platforem a simulátorů, využití těchto modelů a simulátorů v základním a klinickém anatomickém výcviku, chirurgickém plánování a edukaci pacientů a budou vytvářeny nové návrhy řešení.`,
      leadingOrganization: "Hacettepe Üniversitesi",
      participatingOrganizations: [
        "Universita Degli Studi di Perugia",
        "Lokman Hekim Üniversitesi",
        "Univerzita Karlova",
      ],
    },
    // Other languages to be added here...
  };

  useEffect(() => {
    // Fade-in animation for the entire component
    gsap.from(".fade-in", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });

    // Slide-up animation for the text
    gsap.from(".text-animate", {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      delay: 1,
    });

    // Add event listener to detect clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openDropdown = () => {
    setIsDropdownOpen(true);
    gsap.fromTo(
      ".language-option",
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
    );
  };

  const closeDropdown = () => {
    gsap.to(".language-option", {
      x: -50,
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
      onComplete: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const handleLanguageChange = (lang) => {
    closeDropdown();
    gsap.to(dropdownRef.current, {
      onComplete: () => {
        setLanguage(lang);
        gsap.fromTo(
          contentRef.current,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 1 }
        );
      },
    });
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-blue-950 flex items-center justify-center fade-in">
      <div className="min-h-screen max-w-6xl py-20 px-32 bg-slate-50">
        <div className="relative inline-block text-left mb-4" ref={dropdownRef}>
          <div className="flex items-center">
            <button
              onClick={() =>
                isDropdownOpen ? closeDropdown() : openDropdown()
              }
              className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span className="mr-2">
                <img
                  src={flags[language]}
                  alt={language}
                  className="h-5 w-7 inline-block"
                />
              </span>
              {language}
            </button>
            <div
              className={`flex items-center ${
                isDropdownOpen ? "ml-2" : "hidden"
              }`}
            >
              {languages
                .filter((lang) => lang !== language)
                .map((lang) => (
                  <button
                    key={lang}
                    onClick={() => handleLanguageChange(lang)}
                    className="language-option flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <img
                      src={flags[lang]}
                      alt={lang}
                      className="h-5 w-7 mr-2"
                    />
                    {lang}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div ref={contentRef} className="w-full max-w-4xl text-center">
          <div className="flex justify-center">
            <img
              src={me}
              alt="Meeting Flyer"
              className="rounded-lg mb-8 object-cover max-w-sm mt-10 text-animate"
            />
          </div>
          <div className="text-xl my-10 text-center text-animate">
            <span className="font-semibold">{texts[language].venueTitle} </span>
            {texts[language].venue}
            <br />
            <span className="font-semibold">{texts[language].dateTitle} </span>
            {texts[language].date}
            <br />
            <br />
            {texts[language].description}
            <br />
            <br />
            <span className="font-semibold">
              {texts[language].leadingOrganizationTitle}
            </span>{" "}
            {texts[language].leadingOrganization}
            <br />
            <br />
            <span className="font-semibold">
              {texts[language].participatingOrganizationsTitle}
            </span>
            <ul className="list-disc list-inside">
              {texts[language].participatingOrganizations.map((org, index) => (
                <li key={index}>{org}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiplierEventText;
