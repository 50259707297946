import turkiyeEu from "./images/turkiyeEu.jpg";
import turkiyeUlusalAjansi from "./images/turkiyeUlusalAjansi.png";
import erasmus from "./images/erasmus.png";

const LogoBar = () => {
  return (
    <div className="my-8 h-28 flex justify-between items-center">
      <img src={turkiyeEu} alt="turkiyeEu" className="mx-6 h-28" />
      <img
        src={turkiyeUlusalAjansi}
        alt="turkiyeUlusalAjansi"
        className="mx-6 h-24"
      />
      <img src={erasmus} alt="erasmus" className="mx-6 h-16" />
    </div>
  );
};

export default LogoBar;
