import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import ProjectResultsText from "../components/ProjectResultsText";

const ProjectResults = () => {
  return (
    <>
      <Navbar />
      <ProjectResultsText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default ProjectResults;
