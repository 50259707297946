import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import ProjectSummaryText from "../components/ProjectSummaryText";

const ProjectSummary = () => {
  return (
    <>
      <Navbar />
      <ProjectSummaryText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default ProjectSummary;
