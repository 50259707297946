// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.c4om/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5YU5HSQnB01DsMdWlVS3gRqGZbT1GynE",
  authDomain: "eyou3damp.firebaseapp.com",
  projectId: "eyou3damp",
  databaseURL: "https://eyou3damp.europe-west1.firebasedatabase.app/",
  storageBucket: "eyou3damp.appspot.com",
  messagingSenderId: "963914944432",
  appId: "1:963914944432:web:97703e8f66e30eb1af1075",
  measurementId: "G-4G85NWLMD2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export default app;
