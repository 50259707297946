import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";

import en from "./images/flags/gb.png";
import tr from "./images/flags/tr.png";
import cz from "./images/flags/cz.png";
import it from "./images/flags/it.png";

const ProjectSummaryText = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [language, setLanguage] = useState("EN");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const languages = ["EN", "TR", "IT", "CZ"];
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const flags = {
    EN: en,
    TR: tr,
    CZ: cz,
    IT: it,
  };

  const texts = {
    EN: {
      title: "Project Summary",
      content: [
        {
          subtitle: "Background",
          text: [
            "Three-dimensional (3D) anatomical modeling is a topic, which is increasingly important for all health professionals, then for all engineering sciences related to three-dimensional content. It is becoming increasingly popular among young people with its business volume that it can create in different sectors. Increasing the basic skills and competencies in this field, which is among the focus of interest of young entrepreneurs such as health simulations, surgical scenarios, serious games and gamification, is essential for increasing the employability of young people. It is also important issue for Anatomy education, which is a fundamental priority and imperative for health science professionals with providing the knowledge to understand the physical exam, subsequent medical investigations, and how disease affects the human body. In many curricula there has been a trend away from full body cadaveric dissection due to increased curricular emphasis on early clinical exposure, the increasing costs of cadaveric laboratories, and the decreased curricular time due to the inclusion of new subjects in the health school. As a result, educational technologies have been used in the classroom to make the most efficient use of curricular time.",
            "Educators need to find new ways to teach anatomy in a way that is clinically relevant and sustainable. Even with the introduction of new methods such as problem- based learning, team-based learning, and the use of simulation when teaching medical students, the net effect has been limited. Health knowledge is expanding every day but there is a limited learning time for the students. This calls for adoption of methods to make the lessons more compact and better use the students’ time. Anatomy education contains many similarities as well as differences according to the schools affiliated to the world and Europe. There are differences in methods and methods among educational institutions that have different views in each country and even within the country. With the experience of the Covid-19 pandemic, digitization and distance education methods have gained great importance in Anatomy education, along with many educational fields.",
            "The main aim of this project is to provide a multicentered, multidisciplinary platform of standard / personalized, virtual / physical 3D (dimensional) anatomical models in 3 countries that have adopted different education ecoles in Europe (Turkey, Italy and Czech Republic) and 4 universities (Hacettepe, Perugia and Charles universities - State, Lokman Hekim University - Private) and create a supportive and complementary materials that can be used in basic / clinical and surgical Anatomy training with these developed models. The participation of young people, who are interested in this field and working (can work) in different sectors, together with the academic partners of the project by producing their models on this platform and examining the adequacy and suitability of the models in the presence of academic advisors is also one of the important goals for the project.",
          ],
        },
        {
          subtitle: "Objectives",
          text: [
            "With digitization of the existing knowledge about anatomical 3D modeling and its related procedures, we want to achieve digital transformation through development of digital readiness, resilience and capacity by increasing the capacity and readiness of institutions to manage an effective shift towards digital education and purposefully usage of digital technologies in education, training, youth and sport for teaching, learning, assessment and engagement including the development of digital pedagogy and expertise in the use of digital tools for teachers, including accessible and assistive technologies and the creation and innovative use of digital education content.",
            "Young people, who have sufficient knowledge and experience in the field of anatomical 3D modeling, can use this field for both medical and its related sectors like serious gaming, gamification, etc. They will also be more equipped and brave in terms of taking innovative initiative and entrepreneurship in real life.",
          ],
        },
        {
          subtitle: "Implementation",
          text: [
            "According to our project results and working packages, we are planning to use laser scanners and softwares like Qlone, radiological images/sections (DICOM) and softwares (OsiriX, Mimics, Horos, Medical IP etc.), open source model databases, photogrammetry etc. to produce 3D virtual models for our project's first result. We’ll print some of them via 3D printers according to needs of the courses/curriculum. We’ll mostly integrate them into the digital learning platforms like Skatchfab, Moodle, Blackboard etc for our project's second result. We can also produce 3D .pdfs which includes 3d models and any related information (text, picture, schematic drawings etc.) about the topic. We can evaluate these methodologies during the Learning&Teaching&Training activities, assessing the impact of using 3D anatomy models on young learners using a scoping review approach with questionnaires and other modalities for our project's third result.",
          ],
        },
        {
          subtitle: "Results",
          text: [
            "We have three project results/intellectual outcomes, which are formation and production of 3D Models, implementation of 3D Models into digital platform and assessment and evaluation of 3D Models and Platform. With these outcomes, we aim to stimulate innovative learning and teaching practices by developing learning outcomes and student-centered curricula that better meet the learning needs of students and reduce skills mismatches, while also being relevant for the labor market and for the wider society. As a result of this project, solid evidence will be obtained on what kind of needs and equipment young people should have in the rapidly developing and changing digital age on anatomical 3D modeling. And it will facilitate making policy easier and establishing strong links between research and practice. One of the goals after the conclusion of the project will be to create and maintain a digital platform with a large archive where all young people in Europe can access, receive training, develop products and share them with the industry on 3D anatomical modeling and simulation.",
          ],
        },
      ],
    },
    TR: {
      title: "Proje Özeti ",
      content: [
        {
          subtitle: "Arka Plan",
          text: [
            "Üç boyutlu (3D) anatomik modelleme, üç boyutlu içerikle ilgili başta tüm sağlık profesyonelleri, ardından tüm mühendislik bilimleri için önemi giderek artan bir konudur. Farklı sektörlerde yaratabileceği iş hacmiyle gençler arasında giderek daha popüler hale geliyor. Sağlık simülasyonları, cerrahi senaryolar, ciddi oyunlar ve oyunlaştırma gibi genç girişimcilerin ilgi odağı olan bu alanda temel beceri ve yeterliliklerin arttırılması gençlerin istihdam edilebilirliğinin arttırılması açısından şarttır. Sağlık bilimleri profesyonelleri için fizik muayeneyi, sonraki tıbbi araştırmaları ve hastalığın insan vücudunu nasıl etkilediğini anlayacak bilginin sağlanması temel bir öncelik ve zorunluluk olan Anatomi eğitimi için de önemli bir konudur. Pek çok müfredatta, erken klinik maruziyete verilen önemin artması, kadavra laboratuvarlarının artan maliyetleri ve sağlık okullarına yeni konuların dahil edilmesi ve eğitim süresinin azalması nedeniyle tüm vücut kadavra diseksiyonundan uzaklaşma eğilimi olmuştur. Sonuç olarak ders saatlerinin en verimli şekilde kullanılması için sınıflarda eğitim teknolojilerinden faydalanılmaktadır.",
            "Eğitimcilerin anatomiyi klinik açıdan anlamlı ve sürdürülebilir bir şekilde öğretmenin yeni yollarını bulması gerekiyor. Probleme dayalı öğrenme, takıma dayalı öğrenme ve tıp öğrencilerine eğitim verirken simülasyonun kullanılması gibi yeni yöntemlerin tanıtılmasına rağmen net etki sınırlı olmuştur. Sağlık bilgisi her geçen gün genişlemektedir ancak öğrencilerin öğrenme süresi sınırlıdır. Bu, dersleri daha kompakt hale getirecek ve öğrencilerin zamanını daha iyi kullanacak yöntemlerin benimsenmesini gerektirmektedir. Anatomi eğitimi, dünya ve Avrupa'ya bağlı ekollere göre birçok benzerliğin yanı sıra farklılıkları da içermektedir. Her ülkede ve hatta ülke içinde farklı görüşlere sahip eğitim kurumları arasında yöntem ve yöntem farklılıkları bulunmaktadır. Kovid-19 salgını deneyimiyle birlikte birçok eğitim alanıyla birlikte Anatomi eğitiminde de dijitalleşme ve uzaktan eğitim yöntemleri büyük önem kazandı.",
            "Bu projenin temel amacı Avrupa'da farklı eğitim ekollerini benimsemiş 3 ülke (Türkiye, İtalya ve Çek Cumhuriyeti) ve 4 üniversitede standart/kişiselleştirilmiş, sanal/fiziksel 3 boyutlu (boyutsal) anatomik modellerin çok merkezli, çok disiplinli bir platformunu sağlamaktır. (Hacettepe, Perugia ve Charles üniversiteleri- Devlet, Lokman Hekim Üniversitesi- Özel) ve geliştirilen bu modeller ile temel/klinik ve cerrahi Anatomi eğitimlerinde kullanılabilecek destekleyici ve tamamlayıcı materyaller oluşturmaktadır. Bu alana ilgi duyan ve farklı sektörlerde çalışan (çalışabilen) gençlerin projenin ortaklarıyla birlikte bu platformda modellerini üreterek katılımı ve modellerin yeterliliği ve uygunluğunun akademik danışmanlar ve uzmanlar eşliğinde incelenmesi projenin önemli hedeflerinden biridir.",
          ],
        },
        {
          subtitle: "Hedefler",
          text: [
            "Anatomik 3D modelleme ve bununla ilgili prosedürler hakkındaki mevcut bilgilerin dijitalleştirilmesiyle, kurumların dijital eğitime yönelik etkili bir geçişi yönetme kapasitelerini ve hazırlıklarını artırarak dijital hazırlığı, esnekliği ve kapasiteyi geliştirerek dijital dönüşüme ulaşmak istiyoruz. Erişilebilir yardımcı teknolojiler, dijital eğitim içeriğinin oluşturulması ve yenilikçi kullanımı da dahil olmak üzere dijital pedagojinin geliştirilmesi ve öğretmenler için dijital araçların kullanımında uzmanlık dahil olmak üzere eğitim, öğretim, gençlik ve sporda öğretme, öğrenme, değerlendirme ve katılım için dijital teknolojilerin kullanılması önemlidir. Anatomik 3D modelleme alanında yeterli bilgi ve tecrübeye sahip olan gençler, bu alanı hem medikal hem de ilgili sektörler (ciddi oyun, oyunlaştırma vb.) için kullanabilirler. Ayrıca gerçek hayatta girişimcilik, yenilikçi inisiyatif alma konusunda daha donanımlı ve cesur olacaklar.",
          ],
        },
        {
          subtitle: "Uygulama",
          text: [
            "Proje sonuçlarımıza ve çalışma paketlerimize göre, radyolojik görüntüler(DICOM) ve yazılımlar (OsiriX, Mimics, Horos, Medical IP vb.), açık kaynak model veri tabanları, digital fotogrametri vb. kullanmayı planlıyoruz. Projemizin ilk sonucu için 3 boyutlu sanal modeller üretmek. Derslerin/müfredatların ihtiyaçlarına göre bir kısmını 3D yazıcılarla basacağız. Projemizin ikinci sonucu için bunları dijital öğrenme platformunna entegre edeceğiz. Ayrıca konuyla ilgili 3 boyutlu modelleri ve ilgili her türlü bilgiyi (metin, resim, şematik çizimler vb.) içeren 3 boyutlu .pdf'ler de üretebiliriz. Projemizin üçüncü sonucu için anketler ve diğer yöntemler ile kapsamlı bir inceleme yaklaşımı kullanarak 3 boyutlu anatomi modellerinin genç öğrenciler üzerindeki etkisini değerlendirerek Öğrenme&Öğretme&Eğitim faaliyetleri sırasında bu metodolojileri değerlendirebiliriz.",
          ],
        },
        {
          subtitle: "Sonuçlar",
          text: [
            "3D Modellerin oluşturulması ve üretilmesi, 3D Modellerin dijital platforma uygulanması ve 3D Modellerin ve Platformun değerlendirilmesi ve değerlendirilmesi olmak üzere üç proje sonucumuz/fikri çıktımız var. Bu sonuçlarla, öğrencilerin öğrenme ihtiyaçlarını daha iyi karşılayan ve beceri uyumsuzluklarını azaltan, aynı zamanda işgücü piyasasına ve daha geniş anlamda topluma uygun öğrenme çıktıları ve öğrenci merkezli müfredatlar geliştirerek yenilikçi öğrenme ve öğretme uygulamalarını teşvik etmeyi amaçlıyoruz. Bu proje sonucunda anatomik 3D modelleme konusunda hızla gelişen ve değişen dijital çağda gençlerin ne tür ihtiyaçlara ve donanımlara sahip olması gerektiğine dair somut kanıtlar elde edilecek. Ayrıca politikayı kolaylaştırmayı ve araştırma ile uygulama arasında güçlü bağlantılar kurmayı kolaylaştıracaktır. Projenin sonuçlanmasından sonraki hedeflerden biri de, Avrupa'daki tüm gençlerin 3 boyutlu anatomik modelleme ve simülasyon konusunda erişebileceği, eğitim alabileceği, ürün geliştirebileceği ve bunları sektörle paylaşabileceği geniş arşive sahip bir dijital platform oluşturmak ve sürdürmek olacak.",
          ],
        },
      ],
    },
    IT: {
      title: "Sintesi del Progetto",
      content: [
        {
          subtitle: "Sfondo",
          text: [
            "La modellazione anatomica tridimensionale (3D) è un argomento sempre più importante per tutti gli operatori sanitari, quindi per tutte le scienze ingegneristiche legate ai contenuti tridimensionali. Sta diventando sempre più popolare tra i giovani grazie al volume d'affari che riesce a creare in diversi settori. Aumentare le capacità e le competenze di base in questo campo, che è al centro dell’interesse dei giovani imprenditori come le simulazioni sanitarie, gli scenari chirurgici, i serious games e la gamification, è essenziale per aumentare l’occupabilità dei giovani. È anche una questione importante per l'educazione all'anatomia, che è una priorità fondamentale e un imperativo per i professionisti delle scienze sanitarie poiché fornisce le conoscenze per comprendere l'esame fisico, le successive indagini mediche e il modo in cui la malattia colpisce il corpo umano. In molti programmi di studio si è verificata una tendenza ad allontanarsi dalla dissezione cadaverica dell'intero corpo a causa della maggiore enfasi curriculare sull'esposizione clinica precoce, dei costi crescenti dei laboratori su cadavere e della diminuzione del tempo curriculare dovuto all'inclusione di nuove materie nella scuola di salute. Di conseguenza, le tecnologie didattiche sono state utilizzate in classe per sfruttare nel modo più efficiente il tempo curriculare.",
            "Gli educatori devono trovare nuovi modi per insegnare l’anatomia in un modo che sia clinicamente rilevante e sostenibile. Anche con l’introduzione di nuovi metodi come l’apprendimento basato sui problemi, l’apprendimento basato sul team e l’uso della simulazione nell’insegnamento agli studenti di medicina, l’effetto netto è stato limitato. La conoscenza della salute si espande ogni giorno, ma il tempo di apprendimento per gli studenti è limitato. Ciò richiede l’adozione di metodi per rendere le lezioni più compatte e utilizzare meglio il tempo degli studenti. L'educazione all'anatomia contiene molte somiglianze e differenze a seconda delle scuole affiliate al mondo e all'Europa. Esistono differenze nei metodi e nei metodi tra le istituzioni educative che hanno punti di vista diversi in ciascun paese e anche all'interno del paese. Con l’esperienza della pandemia di Covid-19, la digitalizzazione e i metodi di formazione a distanza hanno acquisito grande importanza nella formazione dell’Anatomia, insieme a molti altri campi educativi.",
            "Lo scopo principale di questo progetto è quello di fornire una piattaforma multicentrica e multidisciplinare di modelli anatomici 3D (dimensionali) standard/personalizzati, virtuali/fisici in 3 paesi che hanno adottato diverse scuole di istruzione in Europa (Turchia, Italia e Repubblica Ceca) e 4 università (Università Hacettepe, Perugia e Charles - Statale, Università Lokman Hekim - Privata) e creare materiali di supporto e complementari che possono essere utilizzati nella formazione di anatomia di base/clinica e chirurgica con questi modelli sviluppati. La partecipazione di giovani interessati a questo campo e che lavorano (possono lavorare) in diversi settori, insieme ai partner accademici del progetto producendo i loro modelli su questa piattaforma ed esaminando l'adeguatezza e l'idoneità dei modelli in presenza di consulenti accademici è anche uno degli obiettivi importanti del progetto.",
          ],
        },
        {
          subtitle: "Obiettivi",
          text: [
            "Con la digitalizzazione delle conoscenze esistenti sulla modellazione anatomica 3D e le relative procedure, vogliamo realizzare la trasformazione digitale attraverso lo sviluppo di prontezza, resilienza e capacità digitale, aumentando la capacità e la disponibilità delle istituzioni a gestire un passaggio efficace verso l’educazione digitale e l’uso mirato di tecnologie digitali nell'istruzione, nella formazione, nella gioventù e nello sport per l'insegnamento, l'apprendimento, la valutazione e il coinvolgimento, compreso lo sviluppo della pedagogia digitale e delle competenze nell'uso degli strumenti digitali per gli insegnanti, comprese le tecnologie accessibili e assistive e la creazione e l'uso innovativo di contenuti educativi digitali.",
            "I giovani, che hanno sufficiente conoscenza ed esperienza nel campo della modellazione anatomica 3D, possono utilizzare questo campo sia per la medicina che per i settori correlati come serious gaming, gamification, ecc. Saranno anche più attrezzati e coraggiosi in termini di iniziativa innovativa e imprenditorialità nella vita reale.",
          ],
        },
        {
          subtitle: "Implementazione",
          text: [
            "In base ai risultati del nostro progetto e ai pacchetti di lavoro, stiamo pianificando di utilizzare scanner laser e software come Qlone, immagini/sezioni radiologiche (DICOM) e software (OsiriX, Mimics, Horos, Medical IP ecc.), database di modelli open source, fotogrammetria ecc. per produrre modelli virtuali 3D per il primo risultato del nostro progetto. Ne stamperemo alcuni tramite stampanti 3D in base alle esigenze dei corsi/curriculum. Per il secondo risultato del nostro progetto, li integreremo principalmente nelle piattaforme di apprendimento digitale come Skatchfab, Moodle, Blackboard ecc. Possiamo anche produrre file .pdf 3D che includono modelli 3D e qualsiasi informazione correlata (testo, immagine, disegni schematici ecc.) sull'argomento. Possiamo valutare queste metodologie durante le attività di Learning&Teaching&Training, valutando l'impatto dell'utilizzo di modelli anatomici 3D su giovani studenti utilizzando un approccio di revisione con questionari e altre modalità per il terzo risultato del nostro progetto.",
          ],
        },
        {
          subtitle: "Risultati",
          text: [
            "Abbiamo tre risultati del progetto/risultati intellettuali, che sono la formazione e la produzione di modelli 3D, l'implementazione di modelli 3D nella piattaforma digitale e la valutazione di modelli 3D e piattaforma. Con questi risultati, miriamo a stimolare pratiche di apprendimento e insegnamento innovative sviluppando risultati di apprendimento e programmi di studio incentrati sullo studente che soddisfino meglio le esigenze di apprendimento degli studenti e riducano gli squilibri tra domanda e offerta di competenze, pur essendo rilevanti per il mercato del lavoro e per la società in generale. Come risultato di questo progetto, si otterranno prove concrete sul tipo di esigenze e attrezzature che i giovani dovrebbero avere nell’era digitale in rapido sviluppo e cambiamento sulla modellazione anatomica 3D. E faciliterà la semplificazione delle politiche e la creazione di forti collegamenti tra ricerca e pratica. Uno degli obiettivi dopo la conclusione del progetto sarà quello di creare e mantenere una piattaforma digitale con un ampio archivio a cui tutti i giovani in Europa possano accedere, ricevere formazione, sviluppare prodotti e condividerli con l'industria sulla modellazione e simulazione anatomica 3D.",
          ],
        },
      ],
    },
    CZ: {
      title: "Shrnutí Projektu",
      content: [
        {
          subtitle: "Pozadí",
          text: [
            "Trojrozměrné (3D) anatomické modelování je téma, které je stále důležitější pro všechny zdravotníky, potažmo pro všechny inženýrské vědy související s trojrozměrným obsahem. Mezi mladými lidmi je stále populárnější díky svému obchodnímu objemu, který dokáže vytvořit v různých odvětvích. Zvyšování základních dovedností a kompetencí v této oblasti, která je středem zájmu mladých podnikatelů, jako jsou zdravotní simulace, chirurgické scénáře, vážné hry a gamifikace, je zásadní pro zvýšení zaměstnatelnosti mladých lidí. Je to také důležitá otázka pro výuku anatomie, která je základní prioritou a imperativem pro odborníky ve zdravotnictví s poskytováním znalostí pro pochopení fyzické zkoušky, následných lékařských vyšetření a toho, jak nemoc ovlivňuje lidské tělo. V mnoha učebních osnovách došlo k trendu odklonu od pitvy mrtvého těla celého těla kvůli zvýšenému kurikulárnímu důrazu na ranou klinickou expozici, zvyšujícím se nákladům na laboratoře kadaverózních pacientů a zkrácení vyučovacího času kvůli začlenění nových předmětů do zdravotnické školy. V důsledku toho byly ve třídách použity vzdělávací technologie, aby bylo možné co nejefektivněji využít učební čas.",
            "Pedagogové musí najít nové způsoby, jak učit anatomii způsobem, který je klinicky relevantní a udržitelný. I po zavedení nových metod, jako je problémové učení, týmové učení a použití simulace při výuce studentů medicíny, byl čistý efekt omezený. Zdravotní znalosti se každým dnem rozšiřují, ale studenti mají omezený čas na učení. To vyžaduje přijetí metod, aby byly hodiny kompaktnější a lépe využívaly čas studentů. Výuka anatomie obsahuje mnoho podobností i rozdílů podle škol přidružených ke světu a Evropě. Mezi vzdělávacími institucemi existují rozdíly v metodách a metodách, které mají různé názory v každé zemi a dokonce i v rámci země. Se zkušenostmi s pandemií Covid-19 získaly metody digitalizace a distančního vzdělávání velký význam ve výuce anatomie, spolu s mnoha vzdělávacími obory.",
            "Hlavním cílem tohoto projektu je poskytnout multicentrickou, multidisciplinární platformu standardních / personalizovaných, virtuálních / fyzických 3D (dimenzionálních) anatomických modelů ve 3 zemích, které přijaly různé vzdělávací ecoles v Evropě (Turecko, Itálie a Česká republika) a 4 univerzitách. (Univerzita Hacettepe, Perugia a Charles – státní, Univerzita Lokmana Hekima – soukromá) a vytvořit podpůrné a doplňkové materiály, které lze s těmito vyvinutými modely použít v základní / klinické a chirurgické anatomii. Účast mladých lidí, kteří se o tuto oblast zajímají a pracují (mohou pracovat) v různých sektorech, spolu s akademickými partnery projektu vytvářením svých modelů na této platformě a zkoumáním vhodnosti a vhodnosti modelů za přítomnosti akademičtí poradci je také jedním z důležitých cílů projektu.",
          ],
        },
        {
          subtitle: "Cíle",
          text: [
            "Digitalizací stávajících znalostí o anatomickém 3D modelování a souvisejících postupech chceme dosáhnout digitální transformace prostřednictvím rozvoje digitální připravenosti, odolnosti a kapacity zvýšením kapacity a připravenosti institucí řídit efektivní posun směrem k digitálnímu vzdělávání a cílevědomě využívat digitální technologie ve vzdělávání, odborné přípravě, mládeži a sportu pro výuku, učení, hodnocení a zapojení, včetně rozvoje digitální pedagogiky a odborných znalostí v používání digitálních nástrojů pro učitele, včetně dostupných a pomocných technologií a vytváření a inovativního využívání obsahu digitálního vzdělávání.",
            "Mladí lidé, kteří mají dostatečné znalosti a zkušenosti v oblasti anatomického 3D modelování, mohou tento obor využít jak pro lékařství, tak pro související sektory, jako je vážné hraní, gamifikace atd. Budou také vybavenější a odvážnější, pokud jde o přebírání inovativní iniciativy a podnikání v reálném životě.",
          ],
        },
        {
          subtitle: "Implementace",
          text: [
            "Podle výsledků našich projektů a pracovních balíčků plánujeme používat laserové skenery a software jako Qlone, radiologické snímky/sekce (DICOM) a software (OsiriX, Mimics, Horos, Medical IP atd.), open source modelové databáze, fotogrammetrii atd. vytvořit 3D virtuální modely pro první výsledek našeho projektu. Některé z nich vytiskneme na 3D tiskárnách dle potřeb kurzů/kurikula. Většinou je začleníme do digitálních výukových platforem, jako je Skatchfab, Moodle, Blackboard atd. pro druhý výsledek našeho projektu. Můžeme také vytvořit 3D .pdf, které obsahují 3D modely a veškeré související informace (text, obrázek, schematické výkresy atd.) k tématu. Tyto metodiky můžeme vyhodnotit během aktivit Learning&Teaching&Training, přičemž posoudíme dopad používání 3D anatomických modelů na mladé studenty pomocí přístupu hodnocení rozsahu s dotazníky a dalšími modalitami pro třetí výsledek našeho projektu.",
          ],
        },
        {
          subtitle: "Výsledek",
          text: [
            "Máme tři výsledky projektu/intelektuální výstupy, kterými jsou tvorba a výroba 3D modelů, implementace 3D modelů do digitální platformy a posouzení a vyhodnocení 3D modelů a platformy. S těmito výsledky se snažíme stimulovat inovativní postupy učení a výuky prostřednictvím rozvoje výsledků učení a učebních osnov zaměřených na studenty, které lépe odpovídají vzdělávacím potřebám studentů a snižují nesoulad mezi nabízenými dovednostmi a zároveň jsou relevantní pro trh práce a pro širší společnost. Výsledkem tohoto projektu bude získání solidních důkazů o tom, jaké potřeby a vybavení by mladí lidé měli mít v rychle se rozvíjejícím a měnícím se digitálním věku v oblasti anatomického 3D modelování. A usnadní to zjednodušování politiky a vytváření pevných vazeb mezi výzkumem a praxí. Jedním z cílů po ukončení projektu bude vytvořit a udržovat digitální platformu s velkým archivem, kam budou mít všichni mladí lidé v Evropě přístup, budou moci absolvovat školení, vyvíjet produkty a sdílet je s průmyslem na 3D anatomickém modelování a simulaci.",
          ],
        },
      ],
    },
  };

  useEffect(() => {
    // Fade-in animation for the entire component
    gsap.from(".fade-in", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });

    // Slide-up animation for the text
    gsap.from(".text-animate", {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      delay: 1,
    });

    // Smooth scrolling for navigation links
    const scrollToSection = (targetId) => {
      const targetSection = document.querySelector(targetId);
      targetSection.scrollIntoView({ behavior: "smooth" });
    };

    const navLinks = document.querySelectorAll("a.nav-link");
    navLinks.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        const targetSectionId = link.getAttribute("href");
        scrollToSection(targetSectionId);
      });
    });

    // Function to handle scrolling and toggle the scroll-to-top button visibility
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300; // Adjust the threshold value to control when the button appears
      setShowScrollToTop(scrollY > threshold);
    };

    // Add event listener to detect clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openDropdown = () => {
    setIsDropdownOpen(true);
    gsap.fromTo(
      ".language-option",
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
    );
  };

  const closeDropdown = () => {
    gsap.to(".language-option", {
      x: -50,
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
      onComplete: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const handleLanguageChange = (lang) => {
    closeDropdown();
    gsap.to(dropdownRef.current, {
      onComplete: () => {
        setLanguage(lang);
        gsap.fromTo(
          contentRef.current,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 1 }
        );
      },
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-blue-950 flex items-center justify-center fade-in">
      <div className="min-h-screen max-w-6xl py-20 px-32 bg-slate-50">
        <div className="relative inline-block text-left mb-4" ref={dropdownRef}>
          <div className="flex items-center">
            <button
              onClick={() =>
                isDropdownOpen ? closeDropdown() : openDropdown()
              }
              className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span className="mr-2">
                <img
                  src={flags[language]}
                  alt={language}
                  className="h-5 w-7 inline-block"
                />
              </span>
              {language}
            </button>
            <div
              className={`flex items-center ${
                isDropdownOpen ? "ml-2" : "hidden"
              }`}
            >
              {languages
                .filter((lang) => lang !== language)
                .map((lang) => (
                  <button
                    key={lang}
                    onClick={() => handleLanguageChange(lang)}
                    className="language-option flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <img
                      src={flags[lang]}
                      alt={lang}
                      className="h-5 w-7 mr-2"
                    />
                    {lang}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <nav className="flex justify-end mb-8">
          {texts[language].content.map((section, index) => (
            <a
              key={index}
              href={`#${section.subtitle.toLowerCase()}`}
              className="mr-4 hover:text-blue-700 nav-link"
            >
              {section.subtitle}
            </a>
          ))}
        </nav>
        <div ref={contentRef} className="w-full max-w-4xl">
          <h1 className="text-5xl font-bold mb-4 text-animate">
            {texts[language].title}
          </h1>
          {texts[language].content.map((section, index) => (
            <div key={index} className="text-xl my-10 text-animate">
              <h2 id={section.subtitle.toLowerCase()} className="font-bold">
                {section.subtitle}
              </h2>
              {section.text.map((paragraph, pIndex) => (
                <p key={pIndex} className="text-xl my-4 text-animate">
                  {paragraph}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      {showScrollToTop && (
        <button
          className="fixed bottom-8 right-8 bg-slate-50 hover:text-blue-700 px-3 py-2 rounded fade-in"
          onClick={scrollToTop}
        >
          <ArrowUpwardRoundedIcon />
        </button>
      )}
    </div>
  );
};

export default ProjectSummaryText;
