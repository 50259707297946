import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./pages/Home";
import Signup from "./pages/SignUp";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ThreeDModel from "./pages/ThreeDModel";
import CartPage from "./pages/CartPage";
import About from "./pages/About";
import ProjectSummary from "./pages/ProjectSummary";
import ProjectManagement from "./pages/ProjectManagement";
import ProjectResults from "./pages/ProjectResults";
import Meetings from "./pages/Meetings";
import MultiplierEvent from "./pages/MultiplierEvent";
import OrganisationAndPartners from "./pages/OrganisationAndPartners";
import ELearnings from "./pages/ELearnings";

function App() {
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/what-is-eyou3damp" element={<About />} />
          <Route path="/project-summary" element={<ProjectSummary />} />
          <Route path="/project-management" element={<ProjectManagement />} />
          <Route path="/project-results" element={<ProjectResults />} />
          <Route path="/meetings" element={<Meetings />} />
          <Route path="/multiplier-event" element={<MultiplierEvent />} />
          <Route path="/e-learnings" element={<ELearnings />} />
          <Route
            path="/organisation-and-partners"
            element={<OrganisationAndPartners />}
          />
          <Route
            path="/signup"
            element={isSignedIn ? <Navigate to="/home" /> : <Signup />}
          />
          <Route
            path="/login"
            element={isSignedIn ? <Navigate to="/home" /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={isSignedIn ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route path="/model/:id" element={<ThreeDModel />} />
          <Route
            path="/Cart"
            element={isSignedIn ? <CartPage /> : <Navigate to="/login" />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

function NotFound() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          404 NOT FOUND!
        </h1>
        <p className="text-gray-600">
          Sorry, the page you are looking for could not be found.
        </p>
      </div>
    </div>
  );
}

export default App;
