import React, { useEffect, useState } from "react";
import { database } from "../firebase";
/* import { addModel } from "../redux/cartRedux"; */
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  onValue,
  ref,
  off,
  child,
  push,
  update,
  getDatabase,
  remove,
} from "firebase/database";
import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";

import ModelViewer from "./ModelViewer";
import ConfirmationModal from "./ConfirmationModal";

/* import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"; */
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const ThreeDModelView = () => {
  const { id } = useParams();
  const [stlURL, setStlURL] = useState("");
  const [stlFileName, setStlFileName] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [model, setModel] = useState({});
  const [users, setUsers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareItem, setShareItem] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  /* const dispatch = useDispatch(); */
  const navigate = useNavigate();
  /* const cart = useSelector((state) => state.cart); */
  const currentUser = useSelector((state) => state.user.currentUser);
  const ownedModels = {
    ...(currentUser?.boughtModels ?? {}),
    ...(currentUser?.createdModels ?? {}),
  };
  /* const wishlist = currentUser.wishlist; */
  const isOwned = Object.values(ownedModels).includes(id);
  const modelIsCreatedByUser = Object.values(
    currentUser?.createdModels ?? {}
  ).includes(id);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  /* const isWishlisted = Object.values(wishlist).includes(id);

  const [isClicked, setIsClicked] = useState(false);

   const handleClick = () => {
    setIsClicked(!isClicked);
  }; 

  const isItemInCart = () => cart.models.some((model) => model.id === id); */

  // create users list
  useEffect(() => {
    let usersRef;

    const fetchUsers = async () => {
      try {
        usersRef = ref(database, "/users");
        onValue(usersRef, (snapshot) => {
          const usersData = snapshot.val();
          if (usersData) {
            const userList = Object.keys(usersData).map((id) => {
              const user = usersData[id];
              const boughtModels = user.boughtModels || {};
              const createdModels = user.createdModels || {};
              const wishlist = user.wishlist || {};
              return {
                id: id,
                firstName: user.firstName,
                lastName: user.lastName,
                boughtModels: boughtModels,
                createdModels: createdModels,
                wishlist: wishlist,
              };
            });
            const sortedUsers = userList.sort((a, b) =>
              a.firstName.localeCompare(b.firstName)
            );
            setUsers(sortedUsers);
          }
        });
      } catch (error) {}
    };

    fetchUsers();

    // Clean up the event listeners when the component unmounts
    return () => {
      if (usersRef) {
        off(usersRef);
      }
    };
  }, []);

  // Fetch Model URL here
  useEffect(() => {
    let modelRef;
    const fetchModelData = async () => {
      try {
        if (id) {
          modelRef = ref(database, `/items/${id}`);
          onValue(modelRef, (snapshot) => {
            const modelData = snapshot.val();

            if (modelData) {
              setModel(modelData);
              setStlURL(modelData.stlURL);
              const annotationsArray = modelData.annotations
                ? Object.keys(modelData.annotations).map((key) => ({
                    id: key,
                    ...modelData.annotations[key],
                  }))
                : [];

              setAnnotations(annotationsArray);
              setStlFileName(modelData.stlFileName);
              setImgFileName(modelData.imgFileName);
            }
          });
        }
      } catch (e) {}
    };

    fetchModelData();

    return () => {
      if (modelRef) {
        off(modelRef);
      }
    };
  }, [id]);

  /* // Add to Cart Button Function
  const addToCartButton = () => {
    // Dispatch the addModel action with the item details
    dispatch(addModel({ ...model }));
  };

  const goToCart = () => {
    navigate("/cart");
  }; */

  const handleDownloadButtonClick = (downloadURL, filename) => {
    // Create an invisible anchor element
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = downloadURL;
    anchor.download = filename;

    // Append the anchor element to the document body
    document.body.appendChild(anchor);

    // Trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the document body
    document.body.removeChild(anchor);
  };

  function shareItemFunc(itemId, desiredUserId) {
    // Get a key for a new shared user.
    const newSharedUserKey = push(
      child(ref(database), `/items/${itemId}/sharedUsers`)
    ).key;

    // Write the new shared user's data simultaneously
    const updates = {};
    updates[`/items/${itemId}/sharedUsers/` + newSharedUserKey] = desiredUserId;
    updates[`/users/${desiredUserId}/boughtModels/` + newSharedUserKey] =
      itemId;

    return update(ref(database), updates);
  }

  const handleShareButtonClick = (itemId) => {
    setShareItem(itemId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleShareConfirm = (desiredUserId) => {
    shareItemFunc(shareItem, desiredUserId)
      .then(() => {
        setModalOpen(false);
        setShareItem(null);
      })
      .catch((error) => {
        setModalOpen(false);
        setShareItem(null);
      });
  };

  const handleDeleteButtonClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = (id) => {
    // Perform the delete operation
    const db = getDatabase();
    const modelRef = ref(db, `/items/${id}`);
    remove(modelRef);
    const userRef = ref(db, `/users/${currentUser.id}/createdModels/${id}`);
    remove(userRef);

    const storage = getStorage();
    const stlFileRef = storageRef(storage, stlFileName);
    deleteObject(stlFileRef);
    const imageFileRef = storageRef(storage, imgFileName);
    deleteObject(imageFileRef);

    // Close the modal
    setShowDeleteConfirm(false);

    navigate("/dashboard");
  };

  return (
    <div className="mx-auto my-6">
      <button
        className="absolute top-22 left-20 text-blue-700"
        onClick={() => navigate("/dashboard")}
      >
        <ArrowBackIosNewRoundedIcon />
      </button>
      <h2 className="text-2xl font-semibold mb-4 text-center text-blue-700">
        {model.title}
      </h2>
      <div className="relative">
        {stlURL ? (
          <ModelViewer
            stlURL={stlURL}
            modelColor={model.color}
            itemId={model?.id}
            annotations={annotations}
          />
        ) : null}
      </div>
      <div className="max-w-5xl mx-auto flex items-center justify-center mt-6">
        {
          isOwned && (
            <div className="flex items-center">
              {modelIsCreatedByUser && (
                <>
                  <button
                    className="shadow-md py-3 px-4 mx-3 rounded-lg w-full h-full bg-red-500 text-white font-semibold hover:bg-red-600 transition-colors duration-150 ease-in-out"
                    onClick={() => handleDeleteButtonClick(model?.id)}
                  >
                    Delete
                  </button>
                  <button
                    className="hover:shadow-amber-200 hover:text-amber-500 shadow-md text-gray-700 rounded-lg w-full h-full py-3 px-4 mx-3 hover:motion-safe:animate-pulse"
                    onClick={() => handleShareButtonClick(model?.id)}
                  >
                    <ShareOutlinedIcon />
                  </button>
                </>
              )}
              <button
                className="hover:shadow-cyan-200 hover:text-cyan-500 shadow-md rounded-lg w-full h-full py-3 px-4 mx-3  hover:motion-safe:animate-bounce"
                onClick={() =>
                  handleDownloadButtonClick(model?.stlURL, model?.title)
                }
              >
                <FileDownloadOutlinedIcon />
              </button>
            </div>
          ) /* : isItemInCart() ? (
          <button
            className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 py-2 px-6 mx-4 rounded-md w-1/5 flex justify-between items-center group h-12"
            onClick={goToCart}
          >
            Go to Cart
            <span className="">
              <ShoppingCartCheckoutOutlinedIcon />
            </span>
          </button>
        ) : (
          <button
            className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 py-2 px-6 mx-4 rounded-md w-1/5 flex justify-between items-center group h-12"
            onClick={addToCartButton}
          >
            Add to Cart
            <span className="group-hover:hidden">${model?.price}</span>
            <span className="opacity-0 group-hover:opacity-100 ml-2 hidden group-hover:block">
              <AddShoppingCartRoundedIcon />
            </span>
          </button>
        ) */
        }
        {/* {!isOwned &&
          (isWishlisted ? (
            <div>
              <button
                className="mx-1 shadow-md rounded-lg text-rose-500 h-12"
                onClick={handleClick}
              >
                {isClicked ? (
                  <div className="hover:shadow-rose-200 shadow-md rounded-lg w-full h-full py-3 px-4">
                    <FavoriteBorderIcon />
                  </div>
                ) : (
                  <div className="hover:shadow-rose-200 shadow-md rounded-lg w-full h-full py-3 px-4">
                    <FavoriteIcon />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <div>
              <button
                className="mx-1 shadow-md rounded-lg text-rose-500 h-12"
                onClick={handleClick}
              >
                {isClicked ? (
                  <div className="hover:shadow-rose-200 shadow-md rounded-lg w-full h-full py-3 px-4">
                    <FavoriteIcon />
                  </div>
                ) : (
                  <div className="hover:shadow-rose-200 shadow-md rounded-lg w-full h-full py-3 px-4">
                    <FavoriteBorderIcon />
                  </div>
                )}
              </button>
            </div>
          ))} */}
      </div>
      {isModalOpen && (
        <ShareModal
          itemId={shareItem}
          userList={users}
          onClose={handleModalClose}
          onConfirm={handleShareConfirm}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          onConfirm={() => handleConfirmDelete(model?.id)}
          onCancel={() => setShowDeleteConfirm(false)}
        />
      )}
    </div>
  );
};

function ShareModal({ itemId, userList, onClose, onConfirm }) {
  const [selectedUser, setSelectedUser] = useState("");

  const filteredList = userList.filter((user) => {
    const { boughtModels, createdModels } = user;

    // Check if itemId exists as a value in boughtModels or createdModels
    const isItemInBoughtModels = Object.values(boughtModels).includes(itemId);
    const isItemInCreatedModels = Object.values(createdModels).includes(itemId);

    // Return true if itemId doesn't exist in boughtModels or createdModels
    return !isItemInBoughtModels && !isItemInCreatedModels;
  });

  const handleShareClick = () => {
    if (selectedUser) {
      onConfirm(selectedUser);
    }
  };

  const handleUserClick = (userId) => {
    setSelectedUser(userId);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-10">
      {/* Background */}
      <div className="fixed inset-0 bg-gray-800 opacity-75"></div>

      {/* Modal */}
      <div className="rounded-xl p-8 relative bg-blue-100 w-96">
        <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
          Share Item
        </h2>
        <p className="mb-4 text-gray-500/75 font-semibold">
          Choose a user to share the item with:
        </p>
        {/* User list */}
        <div className="bg-slate-100 rounded-lg overflow-y-auto max-h-60 mb-3">
          <ul className="p-2">
            {filteredList.map((user) => (
              <li
                className={`mb-2 hover:bg-blue-100 text-gray-600 hover:text-gray-900 cursor-pointer px-1 ${
                  selectedUser === user.id ? "bg-blue-200 text-gray-900" : ""
                }`}
                key={user.id}
                onClick={() => handleUserClick(user.id)}
              >
                {`${user.firstName} ${user.lastName}`}
              </li>
            ))}
          </ul>
        </div>
        {/* Close button */}
        <button
          className="bg-red-600 hover:bg-red-700 hover:text-slate-200 text-slate-50 font-semibold py-2 px-4 rounded float-right"
          onClick={onClose}
        >
          Close
        </button>
        {/* Share button */}
        <button
          className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 font-semibold py-2 px-4 rounded float-right mr-2"
          onClick={handleShareClick}
          disabled={!selectedUser}
        >
          Share
        </button>
      </div>
    </div>
  );
}

export default ThreeDModelView;
