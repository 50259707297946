import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { database } from "../firebase";
import {
  getDatabase,
  child,
  off,
  onValue,
  push,
  ref,
  update,
  set,
  remove,
} from "firebase/database";
import { deleteObject, ref as storageRef, getStorage } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
/* import { addModel } from "../redux/cartRedux"; */
import { setCurrentUser } from "../redux/userRedux";
import { gsap } from "gsap";

import UploadModal from "./UploadModal";
import ConfirmationModal from "./ConfirmationModal";

/* import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined"; */
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const DashboardPageView = () => {
  const currentUid = useSelector((state) => state.user.currentUid);
  const currentUser = useSelector((state) => state.user.currentUser);
  /* const cart = useSelector((state) => state.cart); */
  const dispatch = useDispatch();
  /*  const navigate = useNavigate(); */
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [items, setItems] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [shareItem, setShareItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");

  // Upload Modal Related Events
  const name = currentUser?.firstName + " " + currentUser?.lastName;
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleUpload = (modelData) => {
    // Perform the upload logic here
    const createrId = currentUser.id;

    // generate a unique key for model
    const modelKey = push(child(ref(database), `/items`)).key;

    const newModel = {
      ...modelData,
      id: modelKey,
      createrId: createrId,
      sharedUsers: {
        0: "",
      },
    };

    // push it to the database
    set(ref(database, "/items/" + modelKey), newModel);
    set(
      ref(database, "/users/" + createrId + "/createdModels/" + modelKey),
      modelKey
    );
  };

  function shareItemFunc(itemId, desiredUserId) {
    // Get a key for a new shared user.
    const newSharedUserKey = push(
      child(ref(database), `/items/${itemId}/sharedUsers`)
    ).key;

    // Write the new shared user's data simultaneously
    const updates = {};
    updates[`/items/${itemId}/sharedUsers/` + newSharedUserKey] = desiredUserId;
    updates[`/users/${desiredUserId}/boughtModels/` + newSharedUserKey] =
      itemId;

    return update(ref(database), updates);
  }

  // animations
  useEffect(() => {
    // Fade-in animation
    gsap.from(".fade-dashboard", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });

    // Slide-up animation for the text
    gsap.from(".slide-models", {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      delay: 1,
    });
  }, []);

  // Fetch users and items from the database
  useEffect(() => {
    let usersRef;
    let itemsRef;

    const fetchData = async () => {
      try {
        usersRef = ref(database, "/users");
        onValue(usersRef, (snapshot) => {
          const usersData = snapshot.val();
          if (usersData) {
            const userList = Object.keys(usersData).map((id) => {
              const user = usersData[id];
              const boughtModels = user.boughtModels || { 0: "" };
              const createdModels = user.createdModels || { 0: "" };
              const wishlist = user.wishlist || { 0: "" };
              return {
                id: id,
                firstName: user.firstName,
                lastName: user.lastName,
                boughtModels: boughtModels,
                createdModels: createdModels,
                wishlist: wishlist,
              };
            });
            const sortedUsers = userList.sort((a, b) =>
              a.firstName.localeCompare(b.firstName)
            );
            setUsers(sortedUsers);
          }
        });

        itemsRef = ref(database, "/items");
        onValue(itemsRef, (snapshot) => {
          const itemsData = snapshot.val();
          if (itemsData) {
            const itemList = Object.keys(itemsData).map((id) => {
              const item = itemsData[id];
              const sharedUsers = item.sharedUsers || { 0: "" };
              return {
                category: item.category,
                color: item.color,
                createrId: item.createrId,
                id: id,
                imgURL: item.imgURL,
                price: item.price,
                sharedUsers: sharedUsers,
                stlURL: item.stlURL,
                title: item.title,
                imgFileName: item.imgFileName,
                stlFileName: item.stlFileName,
              };
            });
            setItems(itemList);
            const uniqueCat = [
              ...new Set(itemList.map((item) => item.category)),
            ];
            setUniqueCategories(uniqueCat);
          }
        });
      } catch (error) {}
    };

    fetchData();

    // Clean up the event listeners when the component unmounts
    return () => {
      if (itemsRef && usersRef) {
        off(usersRef);
        off(itemsRef);
      }
    };
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const updateCurrentUser = users.find((user) => user.id === currentUid);
      dispatch(setCurrentUser(updateCurrentUser));
    }
  }, [users, currentUid, dispatch]);

  // Apply filtering based on selected user
  useEffect(() => {
    if (selectedUser?.id === currentUid) {
      const filtered = items.filter((item) => {
        // Apply your filter condition here
        return (
          Object.values(selectedUser.boughtModels).includes(item.id) ||
          Object.values(selectedUser.createdModels).includes(item.id)
        );
      });
      setFilteredItems(filtered);
    } else if (selectedUser) {
      const filtered = items.filter((item) => {
        return Object.values(selectedUser.createdModels).includes(item.id);
      });
      setFilteredItems(filtered);
    } else {
      setFilteredItems(items); // Display all items if no user is selected
    }
  }, [selectedUser, currentUid, items]);

  // Apply search filter based on search query
  useEffect(() => {
    const filtered = items.filter((item) => {
      return item.title.toLowerCase().includes(searchQuery.toLowerCase());
    });

    // Apply category filter if a category is selected
    const categoryFiltered = selectedCategory
      ? filtered.filter((item) => item.category === selectedCategory)
      : filtered;

    setFilteredItems(categoryFiltered);
  }, [searchQuery, selectedCategory, items]);

  const handleCategoryFilter = (category) => {
    if (category === selectedCategory) {
      clearCategoryFilter();
    } else {
      setSelectedCategory(category);
    }
  };

  const clearCategoryFilter = () => {
    setSelectedCategory("");
  };

  const handleUserClick = (user) => {
    if (selectedUser === user) {
      setSelectedUser(null);
      // Clear the selected user if it's the same
    } else {
      setSelectedUser(user); // Set the selected user
    }
  };

  /* const goToCart = () => {
    navigate("/cart");
  };

  const isItemInCart = (itemId) =>
    cart.models.find((model) => model.id === itemId);

  const handleAddToCart = (itemId) => {
    // Get the item details based on the itemId
    const item = items.find((item) => item.id === itemId);

    // Dispatch the addModel action with the item details
    dispatch(addModel({ ...item }));
  }; */

  const isItemDownloadable = (itemId) => {
    if (currentUid) {
      const currentUser = users.find((user) => user.id === currentUid);
      if (currentUser) {
        const { boughtModels, createdModels } = currentUser;
        const boughtModelValues = Object.values(boughtModels);
        const createdModelValues = Object.values(createdModels);
        return (
          boughtModelValues.includes(itemId) ||
          createdModelValues.includes(itemId)
        );
      }
    }
    return false;
  };

  const handleDownloadButtonClick = (downloadURL, filename) => {
    // Create an invisible anchor element
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = downloadURL;
    anchor.download = filename;

    // Append the anchor element to the document body
    document.body.appendChild(anchor);

    // Trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the document body
    document.body.removeChild(anchor);
  };

  const handleConfirmDelete = (id) => {
    const itemToDelete = items.find((item) => item.id === id);

    // Perform the delete operation
    const db = getDatabase();
    const modelRef = ref(db, `/items/${id}`);
    remove(modelRef);
    const userRef = ref(db, `/users/${currentUser.id}/createdModels/${id}`);
    remove(userRef);

    const storage = getStorage();
    const stlFileRef = storageRef(storage, itemToDelete.stlFileName);
    deleteObject(stlFileRef);
    const imageFileRef = storageRef(storage, itemToDelete.imgFileName);
    deleteObject(imageFileRef);

    // Close the modal
    setShowDeleteConfirm(false);
  };

  const isItemShareable = (itemId) => {
    if (currentUid) {
      const currentUser = users.find((user) => user.id === currentUid);
      if (currentUser) {
        const { createdModels } = currentUser;
        const createdModelValues = Object.values(createdModels);
        return createdModelValues.includes(itemId);
      }
    }
    return false;
  };

  const handleDeleteButtonClick = (itemId) => {
    setShowDeleteConfirm(true);
    setDeleteItemId(itemId);
  };

  const handleShareButtonClick = (itemId) => {
    setShareItem(itemId);
    setShareModalOpen(true);
  };

  const handleModalClose = () => {
    setShareModalOpen(false);
  };

  const handleShareConfirm = (desiredUserId) => {
    shareItemFunc(shareItem, desiredUserId)
      .then(() => {
        setShareModalOpen(false);
        setShareItem(null);
      })
      .catch((error) => {
        setShareModalOpen(false);
        setShareItem(null);
      });
  };

  return (
    <div className="flex h-full fade-dashboard">
      <div className="w-1/5 p-4 bg-blue-100">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
          />
        </div>
        <div className="mb-4">
          <div className="text-sm font-medium mb-2 text-center py-2 text-gray-600">
            Filter by Category
          </div>
          {items.length > 0 && (
            <button
              className={`mr-2 mb-2 px-2 py-1 rounded shadow-md hover:text-sky-300 hover:bg-blue-900 ${
                selectedCategory === ""
                  ? "bg-blue-800 text-green-400 shadow-blue-300"
                  : "bg-blue-700 text-slate-200"
              }`}
              onClick={clearCategoryFilter}
            >
              All
            </button>
          )}
          {uniqueCategories.map((category) => (
            <button
              key={category}
              className={`mr-2 mb-2 px-2 py-1 rounded shadow-md hover:text-sky-300 hover:bg-blue-900 ${
                selectedCategory === category
                  ? "bg-blue-800 text-green-400 shadow-blue-300"
                  : "bg-blue-700 text-slate-200"
              }`}
              onClick={() => handleCategoryFilter(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="text-sm font-medium mb-2 text-center py-2 text-gray-600">
          Filter by Name
        </div>
        {users.map((user) => (
          <div
            key={user.id}
            className={`cursor-pointer mb-2 p-2 rounded-md px-6 mx-2 hover:text-sky-300 hover:bg-blue-900 font-medium shadow-md ${
              selectedUser && selectedUser.id === user.id
                ? "bg-blue-800 text-green-400 shadow-blue-300"
                : "bg-blue-700 text-slate-200"
            }`}
            onClick={() => handleUserClick(user)}
          >
            {user.firstName} {user.lastName}
          </div>
        ))}
      </div>
      <div className="w-4/5 p-4">
        <h2 className="text-xl font-semibold mb-4 text-center text-gray-700">
          3D MODELS
        </h2>
        <div className="flex flex-wrap justify-center slide-models">
          {filteredItems.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center mx-4 my-4 rounded-xl shadow-xl hover:shadow-blue-200 bg-slate-100 hover:bg-slate-200/75 text-gray-700 hover:text-blue-500"
            >
              <Link to={`/model/${item.id}`}>
                <div className="w-80 h-56 relative">
                  <div className="w-full h-full rounded-xl rounded-b-none overflow-hidden">
                    <img
                      src={item.imgURL}
                      alt={item.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </Link>

              <div className="flex justify-between items-center my-1 w-full h-12">
                <div className="text-lg font-medium mx-3">{item.title}</div>
                <div className="relative group">
                  {
                    isItemDownloadable(item.id) && (
                      <div className="flex">
                        {isItemShareable(item.id) && (
                          <div className="flex">
                            <button
                              className="pr-1 hover:text-red-500"
                              onClick={() => handleDeleteButtonClick(item.id)}
                            >
                              <DeleteOutlinedIcon />{" "}
                              {/* Changed to delete icon */}
                            </button>
                            <button
                              className="pr-1 hover:text-amber-500"
                              onClick={() => handleShareButtonClick(item.id)}
                            >
                              <ShareOutlinedIcon />
                            </button>
                          </div>
                        )}
                        <button
                          className="pr-4 hover:text-cyan-500 hover:motion-safe:animate-bounce"
                          onClick={() =>
                            handleDownloadButtonClick(item.stlURL, item.title)
                          }
                        >
                          <FileDownloadOutlinedIcon />
                        </button>
                      </div>
                    )
                    /* : isItemInCart(item.id) ? (
                    <button
                      className="py-2 px-4 text-green-600 motion-safe:animate-pulse"
                      onClick={goToCart}
                    >
                      <span>
                        <ShoppingCartCheckoutOutlinedIcon />
                      </span>
                    </button>
                  ) : (
                    <button
                      className="py-2 px-4"
                      onClick={() => handleAddToCart(item.id)}
                    >
                      {/* <span className="group-hover:hidden font-semibold">
                        ${item.price.toFixed(2)}
                      </span> }
                      <span className="ml-2">
                        <AddShoppingCartRoundedIcon />
                      </span>
                    </button>
                  ) */
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className="flex items-center fixed bottom-0 right-0 mb-8 mr-8 py-3 px-4 h-12 bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 font-semibold rounded-lg shadow-md opacity-75 hover:opacity-100"
        onClick={handleOpenUploadModal}
      >
        <p className="mr-2">Upload</p>

        <CloudUploadOutlinedIcon />
      </button>
      {isUploadModalOpen && (
        <UploadModal
          onClose={handleCloseUploadModal}
          onUpload={handleUpload}
          name={name}
        />
      )}
      {isShareModalOpen && (
        <ShareModal
          itemId={shareItem}
          userList={users}
          onClose={handleModalClose}
          onConfirm={handleShareConfirm}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          onConfirm={() => handleConfirmDelete(deleteItemId)}
          onCancel={() => setShowDeleteConfirm(false)}
        />
      )}
    </div>
  );
};

function ShareModal({ itemId, userList, onClose, onConfirm }) {
  const [selectedUserId, setSelectedUserId] = useState("");

  const filteredList = userList.filter((user) => {
    const { boughtModels, createdModels } = user;

    // Check if itemId exists as a value in boughtModels or createdModels
    const isItemInBoughtModels = Object.values(boughtModels).includes(itemId);
    const isItemInCreatedModels = Object.values(createdModels).includes(itemId);

    // Return true if itemId doesn't exist in boughtModels or createdModels
    return !isItemInBoughtModels && !isItemInCreatedModels;
  });

  const handleShareClick = () => {
    if (selectedUserId) {
      onConfirm(selectedUserId);
    }
  };

  const handleUserClick = (userId) => {
    setSelectedUserId(userId);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-10">
      {/* Background */}
      <div className="fixed inset-0 bg-gray-800 opacity-75"></div>

      {/* Modal */}
      <div className="rounded-xl p-8 relative bg-blue-100 w-96">
        <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
          Share Item
        </h2>
        <p className="mb-4 text-gray-500/75 font-semibold">
          Choose a user to share the item with:
        </p>
        {/* User list */}
        <div className="bg-slate-100 rounded-lg overflow-y-auto max-h-60 mb-3">
          <ul className="p-2">
            {filteredList.map((user) => (
              <li
                className={`mb-2 hover:bg-blue-100 text-gray-600 hover:text-gray-900 cursor-pointer px-1 ${
                  selectedUserId === user.id ? "bg-blue-200 text-gray-900" : ""
                }`}
                key={user.id}
                onClick={() => handleUserClick(user.id)}
              >
                {`${user.firstName} ${user.lastName}`}
              </li>
            ))}
          </ul>
        </div>
        {/* Close button */}
        <button
          className="bg-red-600 hover:bg-red-700 hover:text-slate-200 text-slate-50 font-semibold py-2 px-4 rounded float-right"
          onClick={onClose}
        >
          Close
        </button>
        {/* Share button */}
        <button
          className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 font-semibold py-2 px-4 rounded float-right mr-2"
          onClick={handleShareClick}
          disabled={!selectedUserId}
        >
          Share
        </button>
      </div>
    </div>
  );
}

export default DashboardPageView;
