import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import DashboardPageView from "../components/DashboardPageView"



const Login = () => {
  return (
    <>
        <Navbar />
        <DashboardPageView />
        <Footer />
    </>
  )
}

export default Login