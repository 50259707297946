import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/userRedux";
import { database } from "../firebase";
import { ref, onValue, off } from "firebase/database";

import NavbarHome from "../components/NavbarHome";
import VideoHeader from "../components/VideoHeader";
import Info from "../components/Info";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const Home = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const userRef = useRef(null);

  useEffect(() => {
    if (user.isSignedIn) {
      userRef.current = ref(database, "users/" + user.currentUid);
      onValue(userRef.current, (snapshot) => {
        const data = snapshot.val();
        dispatch(setCurrentUser(data));
      });
    }

    return () => {
      if (userRef.current) {
        off(userRef.current);
      }
    };
  }, [dispatch, user.currentUid, user.isSignedIn]);

  return (
    <>
      <NavbarHome />
      <VideoHeader />
      <Info />
      <Contact />
      <LogoBar />
      <Footer />
    </>
  );
};

export default Home;
