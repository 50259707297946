import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import SignUpForm from "../components/SignUpForm"



const SignUp = () => {
  return (
    <>
        <Navbar />
        <SignUpForm />
        <Footer />
    </>
  )
}

export default SignUp