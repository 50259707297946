import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";

import videoBg from "./videos/videoBg.mp4";
import imgBg from "./images/videoBg.jpg";
import logo from "./images/logo.png";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import PartnersBarHome from "./PartnersBarHome";

const VideoHeader = () => {
  const navigate = useNavigate();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const logoRef = useRef(null);

  useEffect(() => {
    // Fade-in animation for the entire component
    gsap.from(".video-header", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });

    // Slide-up animation for the text
    gsap.from(".text-animate", {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      delay: 1,
    });

    // Check if it's the first page load or a refresh
    const isFirstLoad = localStorage.getItem("isFirstLoad");
    if (!isFirstLoad) {
      // First page load, set videoLoaded to true
      setVideoLoaded(true);
      localStorage.setItem("isFirstLoad", "false");
    } else {
      // Refresh, video already loaded
      setVideoLoaded(true);
    }

    // Logo circumvolve animation
    const logoElement = logoRef.current;

    const tl = gsap.timeline({ repeat: -1 });
    tl.to(logoElement, {
      rotationY: "+=360", // Rotate around the Y-axis for a 3D effect
      duration: 8,
      ease: "power2.inOut",
      transformOrigin: "50% 50%", // Set the transform origin to the center of the logo
    });

    return () => {
      tl.kill(); // Cleanup the animation on unmount
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden video-header">
      {videoLoaded ? (
        <video
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setVideoLoaded(true)}
        >
          <source src={videoBg} type="video/mp4" />
        </video>
      ) : (
        <img
          className="w-full h-full object-cover"
          src={imgBg}
          alt="static background"
        />
      )}

      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-60"></div>
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center ml-16">
        <img ref={logoRef} src={logo} alt="Logo" className="h-60 w-60 mr-20" />

        <div className="text-slate-100 space-y-3 text-xl">
          {/* <h1 className="text-4xl font-bold pb-2 text-animate">
            Welcome to <span className="text-blue-700">EYOU3DAMP</span>
          </h1> */}
          <ul className="list-non list-inside">
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/what-is-eyou3damp")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>What is EYOU3DAMP?</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/project-summary")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>Project Summary</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/project-management")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>Project Management</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/project-results")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>Project Results</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/meetings")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>Meetings</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/multiplier-event")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>Multiplier Event</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/e-learnings")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>e-Learnings</p>
              </button>
            </li>
            <li className="text-animate">
              <button
                className="my-2 flex items-center text-slate-50 hover:text-blue-500 hover:text-xl transition-all duration-300 transform hover:translate-x-2"
                onClick={() => navigate("/organisation-and-partners")}
              >
                <KeyboardArrowRightRoundedIcon className="mr-2" />
                <p>Organisation and Partners</p>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <PartnersBarHome />
    </div>
  );
};

export default VideoHeader;
