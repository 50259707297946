import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import OrganisationAndPartnersText from "../components/OrganisationAndPartnersText";

const OrganisationAndPartners = () => {
  return (
    <>
      <Navbar />
      <OrganisationAndPartnersText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default OrganisationAndPartners;
