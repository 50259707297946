import React, { useState } from "react";
import { storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";

const colors = [
  { name: "crimson", display: "red", value: "#DC143C" },
  { name: "darkcyan", display: "blue", value: "#008B8B" },
  { name: "slateblue", display: "purple", value: "#6A5ACD" },
  { name: "lightgray", display: "gray", value: "#D3D3D3" },
  { name: "mediumseagreen", display: "green", value: "#3CB371" },
  { name: "orangered", display: "orange", value: "#FF4500" },
  { name: "goldenrod", display: "yellow", value: "#DAA520" },
];

const categories = [
  { id: 0, name: "Head & Neck" },
  { id: 1, name: "Thorax" },
  { id: 2, name: "Upper Extremity" },
  { id: 3, name: "Lower Extremity" },
];

function UploadModal({ onClose, onUpload, name }) {
  const [stlFile, setStlFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [stlUploadProgress, setStlUploadProgress] = useState(0);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [stlURL, setStlURL] = useState("");
  const [imgURL, setImgURL] = useState("");
  const [stlFileName, setStlFileName] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  /* const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(""); */
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  /* const handlePriceChange = (event) => {
    const value = event.target.value;
    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9.]/g, "");
    setPrice(numericValue);
    setPriceError(""); // Clear the error when the value changes
  }; */

  const handleStlFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".stl")) {
      setStlFile(file);
    } else {
      setStlFile(null);
    }
  };

  const handleImageFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImageFile(file);
    } else {
      setImageFile(null);
    }
  };

  const handleUpload = (stlFile, imageFile, name) => {
    const stlFileRef = ref(storage, "/3Dmodels/" + name + "_" + stlFile.name);
    setStlFileName("/3Dmodels/" + name + "_" + stlFile.name);
    const imageFileRef = ref(storage, "/images/" + name + "_" + imageFile.name);
    setImgFileName("/images/" + name + "_" + imageFile.name);

    const uploadStlTask = uploadBytesResumable(stlFileRef, stlFile);
    const uploadImageTask = uploadBytesResumable(imageFileRef, imageFile);

    uploadStlTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setStlUploadProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadStlTask.snapshot.ref).then((downloadURL) => {
          setStlURL(downloadURL);
        });
      }
    );

    uploadImageTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImageUploadProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadImageTask.snapshot.ref).then((downloadURL) => {
          setImgURL(downloadURL);
        });
      }
    );

    Promise.all([uploadStlTask, uploadImageTask]).then(() => {
      setUploadComplete(true);
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Get the input values
    const title = document.getElementById("titleInput").value;
    // const category = document.getElementById("categoryInput").value;
    // const color = document.getElementById("colorInput").value;
    /* const isValidPrice = /^\d+(\.\d+)?$/.test(price);
    if (!isValidPrice) {
      setPriceError("Please enter a valid numeric price.");
      return;
    } */

    // Create an object with STL URL, image URL, and other form inputs
    const modelData = {
      stlURL,
      imgURL,
      stlFileName,
      imgFileName,
      title: title.charAt(0).toUpperCase() + title.slice(1),
      category: selectedCategory,
      color: selectedColor,
      /* price: parseFloat(price), */
    };
    /* setPrice(""); */

    // Call the onUpload callback function with the form data
    onUpload(modelData);
    onClose();
  };

  const renderUploadForm = () => {
    return (
      <>
        <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
          Upload STL and Image Files
        </h2>
        {stlUploadProgress === 0 && imageUploadProgress === 0 ? (
          <div className="flex justify-center items-center mb-4 ">
            <div className="border-4 border-blue-600 border-dashed mr-4 p-4 h-40 w-64">
              <label
                htmlFor="stlFileInput"
                className="block mb-4 mt-6 text-gray-600/75 font-semibold"
              >
                STL File:
              </label>
              <input
                type="file"
                id="stlFileInput"
                accept=".stl"
                onChange={handleStlFileChange}
              />
            </div>

            <div className="border-4 border-blue-600 border-dashed p-4 h-40 w-64">
              <label
                htmlFor="imageFileInput"
                className="block mb-4 mt-6 text-gray-600/75 font-semibold"
              >
                Image File (JPEG, JPG, PNG):
              </label>
              <input
                type="file"
                id="imageFileInput"
                accept="image/jpeg, image/jpg, image/png"
                onChange={handleImageFileChange}
              />
            </div>
          </div>
        ) : null}

        {stlUploadProgress > 0 && stlUploadProgress < 100 ? (
          <div className="mb-4">
            <div className="h-2 relative w-full bg-zinc-200">
              <div
                className="h-2 absolute bg-blue-800 animate-pulse"
                style={{ width: `${stlUploadProgress}%` }}
              ></div>
            </div>
            <div className="my-2 flex justify-between items-center text-green-600 font-medium animate-pulse">
              <p>STL upload progress... </p>
              <div className="mx-1 flex">
                {Math.round(stlUploadProgress)}%
                <svg className="animate-spin h-5 w-5 ml-1" viewBox="0 0 24 24">
                  <RefreshOutlinedIcon />
                </svg>
              </div>
            </div>
          </div>
        ) : stlUploadProgress === 100 ? (
          <div className="mb-4">
            <div className="h-2 relative w-full bg-zinc-200">
              <div
                className="h-2 absolute bg-blue-800"
                style={{ width: `${stlUploadProgress}%` }}
              ></div>
            </div>
            <div className="my-2 flex justify-between items-center text-green-600 font-medium">
              <p>STL upload completed!</p>
              <div className="mr-1">
                <PublishedWithChangesRoundedIcon />
              </div>
            </div>
          </div>
        ) : null}

        {imageUploadProgress > 0 && imageUploadProgress < 100 ? (
          <div className="mb-4">
            <div className="h-2 relative w-full bg-zinc-200">
              <div
                className="h-2 absolute bg-blue-800 animate-pulse"
                style={{ width: `${imageUploadProgress}%` }}
              ></div>
            </div>
            <div className="my-2 flex justify-between items-center text-green-600 font-medium animate-pulse">
              <p>Image upload progress...</p>
              <div className="mx-1 flex">
                {Math.round(imageUploadProgress)}%
                <svg className="animate-spin h-5 w-5 ml-1" viewBox="0 0 24 24">
                  <RefreshOutlinedIcon />
                </svg>
              </div>
            </div>
          </div>
        ) : imageUploadProgress === 100 ? (
          <div className="mb-4">
            <div className="h-2 relative w-full bg-zinc-200">
              <div
                className="h-2 absolute bg-blue-800"
                style={{ width: `${imageUploadProgress}%` }}
              ></div>
            </div>
            <div className="my-2 flex justify-between items-center text-green-600 font-medium">
              <p>Image upload completed!</p>
              <div className="mr-1">
                <PublishedWithChangesRoundedIcon />
              </div>
            </div>
          </div>
        ) : null}
        {/* Buttons */}
        <div className="mt-4 space-x-2 flex justify-end">
          <button
            className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 font-semibold py-2 px-4 rounded float-right"
            onClick={() => handleUpload(stlFile, imageFile, name)}
            disabled={
              !stlFile ||
              !imageFile ||
              stlUploadProgress > 0 ||
              imageUploadProgress > 0
            }
          >
            Upload
          </button>

          <button
            className="bg-red-600 hover:bg-red-700 text-slate-200 font-semibold py-2 px-4 rounded float-right mr-2"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </>
    );
  };

  const renderUploadComplete = () => {
    const handleColorChange = (event) => {
      const newColor = event.target.value;
      setSelectedColor(selectedColor === newColor ? "" : newColor);
    };

    const handleCategoryChange = (event) => {
      const newValue = event.target.value;
      setSelectedCategory(selectedCategory === newValue ? "" : newValue);
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center z-10">
        {/* Background */}
        <div className="fixed inset-0 bg-gray-800 opacity-75"></div>

        {/* Modal */}
        <div className="rounded-xl p-8 relative bg-blue-100">
          {/* Render form with additional inputs */}
          <h3 className="mb-4 text-right text-green-600">Upload Complete!</h3>
          <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-800 text-blue-700">
            Enter Model Information
          </h2>
          <form className="space-y-4" onSubmit={handleFormSubmit}>
            {/* Add form inputs here */}
            <div className="w-96">
              <label
                htmlFor="titleInput"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Title:
              </label>
              <input
                type="text"
                id="titleInput"
                required
                placeholder="Enter title here..."
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              />
            </div>
            <div className="flex items-center space-x-2">
              <label
                htmlFor="categoryInput"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Category:
              </label>
              <div className="flex space-x-2 my-2">
                {categories.map((category) => (
                  <label key={category.id} className="flex items-center">
                    <input
                      type="radio"
                      name="category"
                      value={category.name}
                      onClick={handleCategoryChange}
                      className="mr-1"
                      checked={selectedCategory === category.name}
                    />
                    <div className=""> {category.name} </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <label
                htmlFor="colorInput"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Color:
              </label>
              <div className="flex space-x-2 my-2">
                {colors.map((color) => (
                  <label key={color.name} className="flex items-center">
                    <input
                      type="radio"
                      name="color"
                      value={color.value}
                      onClick={handleColorChange}
                      className="mr-1"
                      checked={color.value === selectedColor}
                    />
                    <div
                      style={{ backgroundColor: color.value }}
                      className="w-6 h-6"
                      title={color.display}
                    />
                  </label>
                ))}
              </div>
            </div>

            {/* <div className="border-b-2 pb-6 border-blue-800">
              <label
                htmlFor="priceInput"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Price:
              </label>
              <input
                type="text"
                id="priceInput"
                required
                value={price}
                onChange={handlePriceChange}
                placeholder="Enter price here..."
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              />
              {priceError && <p className="text-red-600">{priceError}</p>}
            </div> */}

            {/* Submit button */}
            <div className="mt-4 space-x-2 flex justify-end border-t-2 pt-4 border-blue-800">
              <button
                type="submit"
                className="bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 font-semibold py-2 px-4 rounded float-right"
              >
                Submit
              </button>
              <button
                className="bg-red-600 hover:bg-red-700 hover:text-slate-200 text-slate-50 font-semibold py-2 px-4 rounded float-right mr-2"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-10">
      {/* Background */}
      <div className="fixed inset-0 bg-gray-800 opacity-75"></div>

      {/* Modal */}
      <div className="rounded-xl p-8 relative bg-blue-100">
        {/* Conditional rendering based on upload completion */}
        {uploadComplete ? renderUploadComplete() : renderUploadForm()}
      </div>
    </div>
  );
}

export default UploadModal;
