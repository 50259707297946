import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";

import en from "./images/flags/gb.png";
import tr from "./images/flags/tr.png";
import cz from "./images/flags/cz.png";
import it from "./images/flags/it.png";

import tnm1 from "./images/meetings/tnm1.jpeg";
import tnm2 from "./images/meetings/tnm2.jpeg";
import tnm3 from "./images/meetings/tnm3.jpeg";
import tnm4 from "./images/meetings/tnm4.jpeg";
import ltt1 from "./images/meetings/ltt1.jpeg";

const translations = {
  EN: {
    titleTNM: "TNMs (Transnational Meetings)",
    labels: {
      startDate: "Start Date",
      endDate: "End Date",
      organisation: "Receiving Organisation",
      country: "Receiving Country",
      city: "Receiving City",
      participants: "No. of Participants",
    },
    meetings: [
      {
        id: "tnm1",
        title: "Kick-Off Meeting (1st TNM) of EYOU3DAMP",
        description:
          "For description of the project management and the work packages, plans and internal agreement discussion and approval",
        startDate: "5 November 2022",
        endDate: "5 November 2022",
        organisation: "Hacettepe Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm1,
      },
      {
        id: "tnm2",
        title: "2nd TNM of EYOU3DAMP",
        description:
          "For the implementation of models to the training modules, discussion of training activities",
        startDate: "18 October 2023",
        endDate: "19 October 2023",
        organisation: "Universita Degli Studi di Perugia",
        country: "Italy",
        city: "Perugia",
        participants: 8,
        image: tnm2,
      },
      {
        id: "tnm3",
        title: "3rd TNM of EYOU3DAMP",
        description: "For final reporting, future goals, and collaboration",
        startDate: "19 April 2024",
        endDate: "20 April 2024",
        organisation: "Lokman Hekim Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm3,
      },
      {
        id: "tnm4",
        title: "Closure Meeting (4th TNM) of EYOU3DAMP",
        description:
          "For the coordination, update, evaluation of the outcomes, problem-solving, mid-term report preparation",
        startDate: "3 May 2024",
        endDate: "4 May 2024",
        organisation: "Univerzita Karlova",
        country: "Czech Republic",
        city: "Prague",
        participants: 8,
        image: tnm4,
      },
    ],
    titleLTT: "LTT (Learning-Teaching-Education) Activities",
    lttActivities: [
      {
        id: "ltt1",
        title: "LTT Activity 1",
        description: [
          { label: "Activity No.", value: "LTT 1" },
          { label: "Field", value: "Youth" },
          { label: "Activity Type", value: "LTT" },
          {
            label: "Description",
            value: "EYOU3DAMP - LTT1 - All parts of the body",
          },
          { label: "Country of Venue", value: "Türkiye" },
          { label: "No. of Participants", value: 9 },
          { label: "Participants with Special Needs", value: "-" },
          { label: "Accompanying Persons", value: "-" },
          { label: "Is this a long-term activity?", value: "No" },
          {
            label: "Funded Duration (days)",
            value: "5 (including travel days)",
          },
          {
            label: "Participating Organisation",
            value: [
              "Lokman Hekim Üniversitesi",
              "Universita Degli Studi di Perugia",
              "Univerzita Karlova",
            ],
          },
        ],
        image: ltt1,
      },
    ],
  },
  TR: {
    titleTNM: "TNM'ler (Ulusötesi Toplantılar)",
    labels: {
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      organisation: "Alıcı Kuruluş",
      country: "Alıcı Ülke",
      city: "Alıcı Şehir",
      participants: "Katılımcı Sayısı",
    },
    meetings: [
      {
        id: "tnm1",
        title: "EYOU3DAMP'ın Başlangıç Toplantısı (1. TNM)",
        description:
          "Proje yönetiminin ve iş paketlerinin, planların ve iç anlaşma tartışması ve onayının açıklaması için",
        startDate: "5 Kasım 2022",
        endDate: "5 Kasım 2022",
        organisation: "Hacettepe Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm1,
      },
      {
        id: "tnm2",
        title: "EYOU3DAMP'ın 2. TNM'si",
        description:
          "Eğitim modüllerine modellerin uygulanması, eğitim faaliyetlerinin tartışılması için",
        startDate: "18 Ekim 2023",
        endDate: "19 Ekim 2023",
        organisation: "Universita Degli Studi di Perugia",
        country: "İtalya",
        city: "Perugia",
        participants: 8,
        image: tnm2,
      },
      {
        id: "tnm3",
        title: "EYOU3DAMP'ın 3. TNM'si",
        description: "Son raporlama, gelecekteki hedefler ve iş birliği için",
        startDate: "19 Nisan 2024",
        endDate: "20 Nisan 2024",
        organisation: "Lokman Hekim Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm3,
      },
      {
        id: "tnm4",
        title: "EYOU3DAMP Kapanış Toplantısı (4. TNM)",
        description:
          "Koordinasyon, güncelleme, sonuçların değerlendirilmesi, problem çözme, dönem ortası rapor hazırlama için",
        startDate: "3 Mayıs 2024",
        endDate: "4 Mayıs 2024",
        organisation: "Univerzita Karlova",
        country: "Çek Cumhuriyeti",
        city: "Prag",
        participants: 8,
        image: tnm4,
      },
    ],
    titleLTT: "LTT (Öğrenme-Öğretme-Eğitim) Aktiviteleri",
    lttActivities: [
      {
        id: "ltt1",
        title: "LTT Aktivitesi 1",
        description: [
          { label: "Aktivite No.", value: "LTT 1" },
          { label: "Alan", value: "Gençlik" },
          { label: "Aktivite Türü", value: "LTT" },
          {
            label: "Açıklama",
            value: "EYOU3DAMP - LTT1 – Vücudun tüm bölümleri",
          },
          { label: "Ülke Mekan", value: "Türkiye" },
          { label: "Katılımcı Sayısı", value: 9 },
          { label: "Özel İhtiyaçları Olan Katılımcılar", value: "-" },
          { label: "Refakatçi Kişiler", value: "-" },
          { label: "Bu uzun vadeli bir etkinlik mi?", value: "Hayır" },
          {
            label: "Finanse Edilen Süre (gün)",
            value: "5 (seyahat günleri dahil)",
          },
          {
            label: "Katılımcı Kuruluş",
            value: [
              "Lokman Hekim Üniversitesi",
              "Universita Degli Studi di Perugia",
              "Univerzita Karlova",
            ],
          },
        ],
        image: ltt1,
      },
    ],
  },
  IT: {
    titleTNM: "TNM (Transnational Meeting)",
    labels: {
      startDate: "Data di inizio",
      endDate: "Data di fine",
      organisation: "Organizzazione ricevente",
      country: "Paese ricevente",
      city: "Città ricevente",
      participants: "Numero di partecipanti",
    },
    meetings: [
      {
        id: "tnm1",
        title: "Kick-Off Meeting (1° TNM) di EYOU3DAMP",
        description:
          "Per la descrizione della gestione del progetto e dei pacchetti di lavoro, piani e discussione e approvazione degli accordi interni",
        startDate: "5 novembre 2022",
        endDate: "5 novembre 2022",
        organisation: "Hacettepe Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm1,
      },
      {
        id: "tnm2",
        title: "2° TNM di EYOU3DAMP",
        description:
          "Per l'implementazione dei modelli nei moduli di formazione, discussione delle attività di formazione",
        startDate: "18 ottobre 2023",
        endDate: "19 ottobre 2023",
        organisation: "Università degli Studi di Perugia",
        country: "Italia",
        city: "Perugia",
        participants: 8,
        image: tnm2,
      },
      {
        id: "tnm3",
        title: "3° TNM di EYOU3DAMP",
        description:
          "Per la rendicontazione finale, gli obiettivi futuri e la collaborazione",
        startDate: "19 aprile 2024",
        endDate: "20 aprile 2024",
        organisation: "Lokman Hekim Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm3,
      },
      {
        id: "tnm4",
        title: "Riunione di chiusura (4° TNM) di EYOU3DAMP",
        description:
          "Per il coordinamento, l'aggiornamento, la valutazione dei risultati, la risoluzione dei problemi, la preparazione del rapporto di medio termine",
        startDate: "3 maggio 2024",
        endDate: "4 maggio 2024",
        organisation: "Univerzita Karlova",
        country: "Repubblica Ceca",
        city: "Praga",
        participants: 8,
        image: tnm4,
      },
    ],
    titleLTT: "Attività LTT (apprendimento-insegnamento-istruzione)",
    lttActivities: [
      {
        id: "ltt1",
        title: "Attività LTT 1",
        description: [
          { label: "Numero attività", value: "LTT 1" },
          { label: "Campo", value: "Giovani" },
          { label: "Tipo di attività", value: "LTT" },
          {
            label: "Descrizione",
            value: "EYOU3DAMP - LTT1 - Tutte le parti del corpo",
          },
          { label: "Paese della sede", value: "Turchia" },
          { label: "Numero di partecipanti", value: 9 },
          { label: "Partecipanti con esigenze speciali", value: "-" },
          { label: "Accompagnatori", value: "-" },
          { label: "Si tratta di un'attività a lungo termine?", value: "No" },
          {
            label: "Durata finanziata (giorni)",
            value: "5 (inclusi i giorni di viaggio)",
          },
          {
            label: "Organizzazione partecipante",
            value: [
              "Lokman Hekim Üniversitesi",
              "Universita Degli Studi di Perugia",
              "Univerzita Karlova",
            ],
          },
        ],
        image: ltt1,
      },
    ],
  },
  CZ: {
    titleTNM: "TNMs (Mezinárodní setkání)",
    labels: {
      startDate: "Datum zahájení",
      endDate: "Datum ukončení",
      organisation: "Přijímající organizace",
      country: "Přijímající země",
      city: "Přijímající město",
      participants: "Počet účastníků",
    },
    meetings: [
      {
        id: "tnm1",
        title: "Úvodní setkání (1. TNM) EYOU3DAMP",
        description:
          "Pro popis řízení projektu a pracovních balíčků, plánů a projednání a schválení interní dohody",
        startDate: "5. listopadu 2022",
        endDate: "5. listopadu 2022",
        organisation: "Hacettepe Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm1,
      },
      {
        id: "tnm2",
        title: "2. TNM EYOU3DAMP",
        description:
          "Pro implementaci modelů do školicích modulů, diskuse o školicích aktivitách",
        startDate: "18. října 2023",
        endDate: "19. října 2023",
        organisation: "Universita Degli Studi di Perugia",
        country: "Itálie",
        city: "Perugia",
        participants: 8,
        image: tnm2,
      },
      {
        id: "tnm3",
        title: "3. TNM EYOU3DAMP",
        description: "Pro závěrečné zprávy, budoucí cíle a spolupráci",
        startDate: "19. dubna 2024",
        endDate: "20. dubna 2024",
        organisation: "Lokman Hekim Üniversitesi",
        country: "Türkiye",
        city: "Ankara",
        participants: 8,
        image: tnm3,
      },
      {
        id: "tnm4",
        title: "Závěrečné setkání (4. TNM) EYOU3DAMP",
        description:
          "Pro koordinaci, aktualizaci, vyhodnocování výsledků, řešení problémů, přípravu střednědobé zprávy",
        startDate: "3. května 2024",
        endDate: "4. května 2024",
        organisation: "Univerzita Karlova",
        country: "Česká republika",
        city: "Praha",
        participants: 8,
        image: tnm4,
      },
    ],
    titleLTT: "LTT (Learning-Teaching-Education) aktivity",
    lttActivities: [
      {
        id: "ltt1",
        title: "Aktivita LTT 1",
        description: [
          { label: "Č. aktivity", value: "LTT 1" },
          { label: "Obor", value: "Mládež" },
          { label: "Typ aktivity", value: "LTT" },
          {
            label: "Popis",
            value: "EYOU3DAMP - LTT1 - Všechny části těla",
          },
          { label: "Země konání", value: "Türkiye" },
          { label: "Počet účastníků", value: 9 },
          { label: "Účastníci se speciálními potřebami", value: "-" },
          { label: "Doprovázející osoby", value: "-" },
          { label: "Je to dlouhodobá činnost?", value: "Ne" },
          {
            label: "Doba trvání financování (dny)",
            value: "5 (včetně cestovních dnů)",
          },
          {
            label: "Zúčastněná organizace",
            value: [
              "Lokman Hekim Üniversitesi",
              "Universita Degli Studi di Perugia",
              "Univerzita Karlova",
            ],
          },
        ],
        image: ltt1,
      },
    ],
  },
};

const MeetingsText = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [language, setLanguage] = useState("EN");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const languages = ["EN", "TR", "IT", "CZ"];
  const flags = { EN: en, TR: tr, CZ: cz, IT: it };

  const { titleTNM, meetings, titleLTT, lttActivities, labels } =
    translations[language];

  useEffect(() => {
    // Fade-in animation for the entire component
    gsap.from(".fade-in", {
      opacity: 0,
      duration: 1,
      delay: 0.5,
    });

    // Slide-up animation for the text
    gsap.from(".text-animate", {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      delay: 1,
    });

    // Smooth scrolling for navigation links
    const scrollToSection = (targetId) => {
      const targetSection = document.querySelector(targetId);
      targetSection.scrollIntoView({ behavior: "smooth" });
    };

    const navLinks = document.querySelectorAll("a.nav-link");
    navLinks.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        const targetSectionId = link.getAttribute("href");
        scrollToSection(targetSectionId);
      });
    });

    // Add event listener to detect clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Function to handle scrolling and toggle the scroll-to-top button visibility
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300; // Adjust the threshold value to control when the button appears
      setShowScrollToTop(scrollY > threshold);
    };

    // Add event listener to handle scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openDropdown = () => {
    setIsDropdownOpen(true);
    gsap.fromTo(
      ".language-option",
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
    );
  };

  const closeDropdown = () => {
    gsap.to(".language-option", {
      x: -50,
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
      onComplete: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const handleLanguageChange = (lang) => {
    closeDropdown();
    gsap.to(dropdownRef.current, {
      onComplete: () => {
        setLanguage(lang);
        gsap.fromTo(
          contentRef.current,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 1 }
        );
      },
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-blue-950 flex items-center justify-center fade-in">
      <div className="min-h-screen max-w-6xl py-20 px-32 bg-slate-50">
        {/* Language Dropdown */}
        <div className="relative inline-block text-left mb-4" ref={dropdownRef}>
          <div className="flex items-center">
            <button
              onClick={() =>
                isDropdownOpen ? closeDropdown() : openDropdown()
              }
              className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span className="mr-2">
                <img
                  src={flags[language]}
                  alt={language}
                  className="h-5 w-7 inline-block"
                />
              </span>
              {language}
            </button>
            <div
              className={`flex items-center ${
                isDropdownOpen ? "ml-2" : "hidden"
              }`}
            >
              {languages
                .filter((lang) => lang !== language)
                .map((lang) => (
                  <button
                    key={lang}
                    onClick={() => handleLanguageChange(lang)}
                    className="language-option flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <img
                      src={flags[lang]}
                      alt={lang}
                      className="h-5 w-7 mr-2"
                    />
                    {lang}
                  </button>
                ))}
            </div>
          </div>
        </div>

        {/* Navigation bar */}
        <nav className="flex justify-between mb-8">
          {meetings.map((meeting, index) => (
            <a
              key={meeting.id}
              href={`#${meeting.id}`}
              className="mr-4 hover:text-blue-700 nav-link"
            >
              TNM {index + 1}
            </a>
          ))}
          {lttActivities.map((activity) => (
            <a
              key={activity.id}
              href={`#${activity.id}`}
              className="mr-4 hover:text-blue-700 nav-link"
            >
              LTT 1
            </a>
          ))}
        </nav>
        <div ref={contentRef}>
          {/* TNM Section */}
          <h1 className="text-4xl font-bold mb-4 text-animate">{titleTNM}</h1>
          {meetings.map((meeting) => (
            <div key={meeting.id} className="text-xl my-10 text-animate">
              <div className="flex items-center space-x-4 mb-10">
                <div className="flex-1 text-left">
                  <span id={meeting.id} className="font-bold text-2xl">
                    {meeting.title}
                  </span>
                  <br />
                  <br />
                  {meeting.description}
                  <br />
                  <br />
                  <span className="font-semibold">
                    {labels.startDate}:{" "}
                  </span>{" "}
                  {meeting.startDate}
                  <br />
                  <span className="font-semibold">{labels.endDate}: </span>{" "}
                  {meeting.endDate}
                  <br />
                  <span className="font-semibold">
                    {labels.organisation}:{" "}
                  </span>{" "}
                  {meeting.organisation}
                  <br />
                  <span className="font-semibold">{labels.country}: </span>{" "}
                  {meeting.country}
                  <br />
                  <span className="font-semibold">{labels.city}: </span>{" "}
                  {meeting.city}
                  <br />
                  <span className="font-semibold">
                    {labels.participants}:{" "}
                  </span>{" "}
                  {meeting.participants}
                  <br />
                  <br />
                </div>
                <img
                  src={meeting.image}
                  alt="Meeting Flyer"
                  className="rounded-lg max-w-xs w-full object-cover"
                />
              </div>
            </div>
          ))}

          {/* LTT Section */}
          <h1 className="text-4xl font-bold mb-4 text-animate">{titleLTT}</h1>
          {lttActivities.map((activity) => (
            <div key={activity.id} className="text-xl my-10 text-animate">
              <div className="flex items-center space-x-4 mb-10">
                <div className="flex-1 text-left">
                  <span id={activity.id} className="font-bold text-2xl">
                    {activity.title}
                  </span>
                  <br />
                  <br />
                  {activity.description.map((desc, index) => (
                    <div key={index}>
                      <span className="font-semibold">{desc.label}: </span>
                      {Array.isArray(desc.value) ? (
                        <ul className="list-disc ml-5">
                          {desc.value.map((org, idx) => (
                            <li key={idx}>{org}</li>
                          ))}
                        </ul>
                      ) : (
                        desc.value
                      )}
                      <br />
                    </div>
                  ))}
                  <br />
                </div>
                <img
                  src={activity.image}
                  alt="Meeting Flyer"
                  className="rounded-lg max-w-xs w-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Scroll to Top Button */}
      {showScrollToTop && (
        <button
          className="fixed bottom-8 right-8 bg-slate-50 hover:text-blue-700 px-3 py-2 rounded fade-in"
          onClick={scrollToTop}
        >
          <ArrowUpwardRoundedIcon />
        </button>
      )}
    </div>
  );
};

export default MeetingsText;
