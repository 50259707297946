import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import ELearningsText from "../components/ELearningsText";

const ELearnings = () => {
  return (
    <>
      <Navbar />
      <ELearningsText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default ELearnings;
