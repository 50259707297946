import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import en from "./images/flags/gb.png";
import tr from "./images/flags/tr.png";
import cz from "./images/flags/cz.png";
import it from "./images/flags/it.png";

// Import images for each step
import img1 from "./images/guide/img1.png";
import img21 from "./images/guide/img2-1.png";
import img22 from "./images/guide/img2-2.png";
import img3 from "./images/guide/img3.png";
import img4 from "./images/guide/img4.png";
import img5 from "./images/guide/img5.png";
import img6 from "./images/guide/img6.png";
import img7 from "./images/guide/img7.png";
import img81 from "./images/guide/img8-1.png";
import img82 from "./images/guide/img8-2.png";
import img83 from "./images/guide/img8-3.png";
import img9 from "./images/guide/img9.png";

import abdomen from "./images/pdfImg/abdomen.png";
import airway from "./images/pdfImg/airway.png";
import artery from "./images/pdfImg/artery.png";
import bladder from "./images/pdfImg/bladder.png";
import brain from "./images/pdfImg/brain.png";
import brain_artery from "./images/pdfImg/brain_artery.png";
import brain_artery_vein from "./images/pdfImg/brain_artery_vein.png";
import brain_vein from "./images/pdfImg/brain_vein.png";
import clavicula from "./images/pdfImg/clavicula.png";
import colon from "./images/pdfImg/colon.png";
import cranium from "./images/pdfImg/cranium.png";
import duodenum from "./images/pdfImg/duodenum.png";
import esophagus from "./images/pdfImg/esophagus.png";
import gallbladder from "./images/pdfImg/gallbladder.png";
import hyoid from "./images/pdfImg/hyoid.png";
import kidney_bladder_system from "./images/pdfImg/kidney_bladder_system.png";
import kidney_tumor from "./images/pdfImg/kidney_tumor.png";
import left_ankle from "./images/pdfImg/left_ankle.png";
import left_femur from "./images/pdfImg/left_femur.png";
import left_fibula from "./images/pdfImg/left_fibula.png";
import left_kidney from "./images/pdfImg/left_kidney.png";
import left_lung from "./images/pdfImg/left_lung.png";
import left_pelvis from "./images/pdfImg/left_pelvis.png";
import left_tibia from "./images/pdfImg/left_tibia.png";
import liver from "./images/pdfImg/liver.png";
import lumbar from "./images/pdfImg/lumbar.png";
import mandibula from "./images/pdfImg/mandibula.png";
import pancreas from "./images/pdfImg/pancreas.png";
import prostate from "./images/pdfImg/prostate.png";
import rectum from "./images/pdfImg/rectum.png";
import right_femur from "./images/pdfImg/right_femur.png";
import right_fibula from "./images/pdfImg/right_fibula.png";
import right_kidney from "./images/pdfImg/right_kidney.png";
import right_lung from "./images/pdfImg/right_lung.png";
import right_pelvis from "./images/pdfImg/right_pelvis.png";
import right_tibia from "./images/pdfImg/right_tibia.png";
import sacrum from "./images/pdfImg/sacrum.png";
import scapula from "./images/pdfImg/scapula.png";
import servikal from "./images/pdfImg/servikal.png";
import sfenoid from "./images/pdfImg/sfenoid.png";
import small_bowel from "./images/pdfImg/small_bowel.png";
import spleen from "./images/pdfImg/spleen.png";
import stomach from "./images/pdfImg/stomach.png";
import temporal from "./images/pdfImg/temporal.png";
import testicle from "./images/pdfImg/testicle.png";
import torakal_and_rib_cage from "./images/pdfImg/torakal_and_rib_cage.png";
import toraks from "./images/pdfImg/toraks.png";
import urethra from "./images/pdfImg/urethra.png";
import urology from "./images/pdfImg/urology.png";
import vas_deferens from "./images/pdfImg/vas_deferens.png";

const pdfImages = [
  {
    src: abdomen,
    alt: "Abdomen",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fabdomen.pdf?alt=media&token=ed5dcb65-751d-48bd-9124-b645cc98ef28",
  },

  {
    src: airway,
    alt: "Airway",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fairway.pdf?alt=media&token=757e93b7-da13-40e2-a4a8-85761b186502",
  },
  {
    src: artery,
    alt: "Artery",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fartery.pdf?alt=media&token=e437fb43-7e25-4674-ae2e-2c862cfa3226",
  },
  {
    src: bladder,
    alt: "Bladder",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fbladder.pdf?alt=media&token=fa09fb07-0ca9-448c-a3af-a48936e336c1",
  },
  {
    src: brain,
    alt: "Brain",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fbrain.pdf?alt=media&token=109c094c-13cd-4ec4-a976-db9e62d2f3b0",
  },
  {
    src: brain_artery,
    alt: "Brain Artery",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fbrain%20artery.pdf?alt=media&token=433fe479-7054-41d2-857b-9fd0bbebd6d5",
  },
  {
    src: brain_artery_vein,
    alt: "Brain Artery Vein",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fbrain%20artery-vein.pdf?alt=media&token=61c6df63-56e6-483a-b02a-115d396b63b7",
  },
  {
    src: brain_vein,
    alt: "Brain Vein",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fbrain%20vein.pdf?alt=media&token=2fb5e88b-365a-46b0-94ab-a2f91217335e",
  },
  {
    src: clavicula,
    alt: "Clavicula",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fclavicula.pdf?alt=media&token=f419fe9b-e077-46df-8c1c-8fa6ac19986e",
  },
  {
    src: colon,
    alt: "Colon",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fcolon.pdf?alt=media&token=d4936ba9-e30e-41e3-835e-a8c90eb8ce2c",
  },
  {
    src: cranium,
    alt: "Cranium",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fcranium.pdf?alt=media&token=b301d0cf-eb76-4b52-94cb-16d1199429cc",
  },
  {
    src: duodenum,
    alt: "Duodenum",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fduodenum.pdf?alt=media&token=bd5f9792-4709-4b2e-aa64-8f0ddef7b27b",
  },
  {
    src: esophagus,
    alt: "Esophagus",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fesophagus.pdf?alt=media&token=62e07102-4d93-47a6-9225-edaacb9ee2a5",
  },
  {
    src: gallbladder,
    alt: "Gallbladder",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fgallbladder.pdf?alt=media&token=4f3af42a-7384-4568-b948-32e2234475ed",
  },
  {
    src: hyoid,
    alt: "Hyoid",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fhyoid.pdf?alt=media&token=b9c90b32-12e9-4716-9c0f-a8ebf0bb9578",
  },
  {
    src: kidney_bladder_system,
    alt: "Kidney Bladder System",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fkidney_bladder_system.pdf?alt=media&token=10d6f916-fd94-4159-b897-ae2dc6de2beb",
  },
  {
    src: kidney_tumor,
    alt: "Kidney Tumor",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fkidney-tumor.pdf?alt=media&token=bc325fa6-3237-415a-a95d-f508683a39f6",
  },
  {
    src: left_ankle,
    alt: "Left Ankle",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20ankle.pdf?alt=media&token=13e6aba0-0e09-4b32-a8f4-d0d8da922a6a",
  },
  {
    src: left_femur,
    alt: "Left Femur",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20femur.pdf?alt=media&token=3b76c6dc-5b54-43e9-a56d-e57d6ac2641a",
  },
  {
    src: left_fibula,
    alt: "Left Fibula",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20fibula.pdf?alt=media&token=c6461410-5591-4639-8cb5-c54275a9a64f",
  },
  {
    src: left_kidney,
    alt: "Left Kidney",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20kidney.pdf?alt=media&token=c4da4952-43e9-4c94-bc97-96eeaa2840b6",
  },
  {
    src: left_lung,
    alt: "Left Lung",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20lung.pdf?alt=media&token=df62c9cb-67f3-4d60-b725-022eb485765a",
  },
  {
    src: left_pelvis,
    alt: "Left Pelvis",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20pelvis.pdf?alt=media&token=169df00f-1ba7-4ca7-bb29-7b888de9884f",
  },
  {
    src: left_tibia,
    alt: "Left Tibia",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fleft%20tibia.pdf?alt=media&token=cf572c18-6af3-41d5-9cca-0db346b56bdf",
  },
  {
    src: liver,
    alt: "Liver",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fliver.pdf?alt=media&token=fda3f914-58f5-48e7-b6c8-39651c602688",
  },
  {
    src: lumbar,
    alt: "Lumbar",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Flumbar.pdf?alt=media&token=32602d5f-6a5d-43ba-b362-0f09362cac1a",
  },
  {
    src: mandibula,
    alt: "Mandibula",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fmandibula.pdf?alt=media&token=e666de1a-959c-4981-a6bb-def7e38a9496",
  },
  {
    src: pancreas,
    alt: "Pancreas",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fpancreas.pdf?alt=media&token=31bde7d1-d989-4cb6-863d-3859f3958df6",
  },
  {
    src: prostate,
    alt: "Prostate",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fprostate.pdf?alt=media&token=e88386e9-239f-41cf-a866-8cd50768bda3",
  },
  {
    src: rectum,
    alt: "Rectum",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Frectum.pdf?alt=media&token=8da9985e-33b5-4175-9779-a875333e6114",
  },
  {
    src: right_femur,
    alt: "Right Femur",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fright%20femur.pdf?alt=media&token=1ecc49a6-8b01-4efa-b748-5f5cf0f7bba2",
  },
  {
    src: right_fibula,
    alt: "Right Fibula",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fright%20fibula.pdf?alt=media&token=6514eac8-6071-43f3-8ac3-b8286e01805c",
  },
  {
    src: right_kidney,
    alt: "Right Kidney",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fright%20kidney.pdf?alt=media&token=f71dfb88-0ed4-402c-860e-d10beba9877e",
  },
  {
    src: right_lung,
    alt: "Right Lung",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fright%20lung.pdf?alt=media&token=2b3210bc-8023-41f4-81d7-0aaed0d9a4a2",
  },
  {
    src: right_pelvis,
    alt: "Right Pelvis",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fright%20pelvis.pdf?alt=media&token=6f1e6c62-89f0-4d23-b622-e82b3c832dd6",
  },
  {
    src: right_tibia,
    alt: "Right Tibia",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fright%20tibia.pdf?alt=media&token=4dcc510b-6b7b-44fd-85bf-a74fc60852d5",
  },
  {
    src: sacrum,
    alt: "Sacrum",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fsacrum.pdf?alt=media&token=99ae3d88-58dc-45b9-8be1-40bb47dc7736",
  },
  {
    src: scapula,
    alt: "Scapula",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fscapula.pdf?alt=media&token=30990f99-a9cc-467d-89c2-cd75557221c2",
  },
  {
    src: servikal,
    alt: "Servikal",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fservikal.pdf?alt=media&token=c1f5e0d7-5c4e-4791-bded-bfad6e4433c6",
  },
  {
    src: sfenoid,
    alt: "Sfenoid",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fsfenoid.pdf?alt=media&token=25fd8d2c-875d-4058-91a2-4a19dd2cf7c0",
  },
  {
    src: small_bowel,
    alt: "Small Bowel",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fsmall%20bowel.pdf?alt=media&token=b1c39333-2c17-498f-a85b-abe0cc9ac809",
  },
  {
    src: spleen,
    alt: "Spleen",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fspleen.pdf?alt=media&token=06c5e0e8-01e0-48e6-a293-0bd7f1a829ed",
  },
  {
    src: stomach,
    alt: "Stomach",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fstomach.pdf?alt=media&token=410c6a2b-fd1a-4d31-8ead-ed211071458c",
  },
  {
    src: temporal,
    alt: "Temporal",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Ftemporal.pdf?alt=media&token=36d372fb-e59b-4154-8559-c3e1533f87d6",
  },
  {
    src: testicle,
    alt: "Testicle",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Ftesticle.pdf?alt=media&token=8a713bec-48a8-455d-8400-e4fe77900d60",
  },
  {
    src: torakal_and_rib_cage,
    alt: "Torakal and Rib Cage",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Ftorakal%2Brib%20cage.pdf?alt=media&token=8b03bf4e-b45d-46cd-99eb-1736421b70fb",
  },
  {
    src: toraks,
    alt: "Toraks",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Ftoraks.pdf?alt=media&token=54d27432-62b5-4f0f-9a89-c927b715b6bf",
  },
  {
    src: urethra,
    alt: "Urethra",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Furethra.pdf?alt=media&token=7b4c5442-3322-4814-a17d-623124d334b9",
  },
  {
    src: urology,
    alt: "Urology",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Furology.pdf?alt=media&token=fd202a4e-20f3-407f-9f77-c4b89f96b474",
  },
  {
    src: vas_deferens,
    alt: "Vas Deferens",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/pdfFiles%2Fvas%20deferens.pdf?alt=media&token=598f52bf-90b2-466a-8b05-103baa6973f1",
  },
];

const images = {
  step1: [{ src: img1, className: "w-5/6" }],
  step2: [
    { src: img21, className: "w-3/5" },
    { src: img22, className: "max-w-sm" },
  ],
  step3: [{ src: img3, className: "w-2/3" }],
  step4: [{ src: img4, className: "w-2/3" }],
  step5: [{ src: img5, className: "w-2/3" }],
  step6: [{ src: img6, className: "w-2/3" }],
  step7: [{ src: img7, className: "w-2/3" }],
  step8: [
    { src: img81, className: "w-2/3" },
    { src: img82, className: "w-2/3" },
    { src: img83, className: "w-2/3" },
  ],
  step9: [{ src: img9, className: "w-2/3" }],
};

const content = {
  EN: {
    title: "European Youth 3D Anatomical Modeling Platform User Guide",
    downloadUrl:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/assessmentQuestionnaire%2FassessmentQuestionnaire_EN.docx?alt=media&token=c0998c63-c589-43e8-8fa8-a303a6575eb3",
    downloadText: "Download Assessment Questionnaire",
    steps: [
      {
        text: "Enter ‘https://eyou3damp.org/’ into your web browser.",
        images: images.step1,
      },
      {
        text: "The platform's webpage will open. You can find information about the project from the relevant tabs. Click on the 'Login' button to access the platform.",
        images: images.step2,
      },
      {
        text: "To create a new account, click on the 'Sign Up' tab. After completing the registration process, an activation link will be sent to your email address. Complete the activation process to finalize your registration.",
        images: images.step3,
      },
      {
        text: "Once registration is complete, log in to the platform using your email address and the password you created.",
        images: images.step4,
      },
      {
        text: "After logging in, the platform's dashboard will open.",
        images: images.step5,
      },
      {
        text: "Go to the '3D Models' page. Here, you can browse models uploaded by platform members.",
        images: images.step6,
      },
      {
        text: "To upload your own 3D anatomical model STL file, select the 'Upload' tab. Then, upload your STL file and the relevant model image in the provided fields.",
        images: images.step7,
      },
      {
        text: "After uploading, you can access the model scene by selecting the desired 3D model. You can take measurements on the models, switch to sectional views, and leave notes on relevant anatomical points.",
        images: images.step8,
      },
      {
        text: "To log out of the platform, click on the 'Log Out' tab.",
        images: images.step9,
      },
    ],
  },
  TR: {
    title: "European Youth 3D Anatomical Modeling Platform Kullanıcı Kılavuzu",
    downloadUrl:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/assessmentQuestionnaire%2FassessmentQuestionnaire_TR.docx?alt=media&token=528a43db-3d99-4bbb-82f8-6fdc94cbfea8",
    downloadText: "Değerlendirme Anketini İndir",
    steps: [
      {
        text: "Tarayıcınıza ‘https://eyou3damp.org/’ adresini girin.",
        images: images.step1,
      },
      {
        text: `Platformun web sayfası açılır. İlgili sekmelerden proje hakkında bilgiler alınabilir. Platformda oturum açmak için "Giriş Yap" butonuna tıklayın.`,
        images: images.step2,
      },
      {
        text: `Platformda yeni bir hesap oluşturmak için "Kayıt Ol" sekmesine tıklayın. Kayıt işlemini tamamladıktan sonra, mail adresinize bir aktivasyon bağlantısı gönderilir. Onay işlemini gerçekleştirdikten sonra kayıt işleminiz tamamlanmış olacaktır.`,
        images: images.step3,
      },
      {
        text: `Kayıt işlemi tamamlandıktan sonra, mail adresiniz ve oluşturduğunuz şifre ile platforma giriş yapın.`,
        images: images.step4,
      },
      {
        text: `Giriş yaptıktan sonra platformun gösterge paneli açılır.`,
        images: images.step5,
      },
      {
        text: `"3D Modeller" sayfasına gidin. Burada, platform üyeleri tarafından yüklenen modelleri inceleyebilirsiniz.`,
        images: images.step6,
      },
      {
        text: `Kendi 3D anatomik model STL dosyanızı yüklemek için "Yükle" sekmesini seçin. Ardından ilgili alanlara STL dosyanızı ve modelin resmini yükleyin.`,
        images: images.step7,
      },
      {
        text: `Yüklediğiniz 3D modeller arasında seçim yaparak model sahnesine giriş yapabilirsiniz. Modeller üzerinde ölçüm yapabilir, kesit görünümlerine geçebilir ve ilgili anatomik noktalara not bırakabilirsiniz.`,
        images: images.step8,
      },
      {
        text: `Platformdan çıkış yapmak için "Çıkış Yap" sekmesine tıklayın.`,
        images: images.step9,
      },
    ],
  },
  IT: {
    title:
      "Guida Per L'Utente Della Piattaforma Di Modellazione Anatomica 3D Per I Giovani Europei",
    downloadUrl:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/assessmentQuestionnaire%2FassessmentQuestionnaire_IT.docx?alt=media&token=9d7895de-a6f4-46b6-b815-89cfb805a866",
    downloadText: "Scarica il sondaggio di valutazione",
    steps: [
      {
        text: `Inserisci 'https://eyou3damp.org/' nel tuo browser web.`,
        images: images.step1,
      },
      {
        text: `Si aprirà la pagina web della piattaforma. Puoi trovare informazioni sul progetto dalle schede pertinenti. Clicca sul pulsante "Login" per accedere alla piattaforma.`,
        images: images.step2,
      },
      {
        text: `Per creare un nuovo account, clicca sulla scheda "Sign Up". Dopo aver completato la procedura di registrazione, un link di attivazione verrà inviato al tuo indirizzo email. Completa la procedura di attivazione per finalizzare la tua registrazione.`,
        images: images.step3,
      },
      {
        text: `Una volta completata la registrazione, accedi alla piattaforma utilizzando il tuo indirizzo email e la password che hai creato.`,
        images: images.step4,
      },
      {
        text: `Dopo aver effettuato l'accesso, si aprirà la dashboard della piattaforma`,
        images: images.step5,
      },
      {
        text: `Vai alla pagina "Modelli 3D". Qui puoi sfogliare i modelli caricati dai membri della piattaforma.`,
        images: images.step6,
      },
      {
        text: `Per caricare il tuo file STL del modello anatomico 3D, seleziona la scheda "Carica". Quindi, carica il tuo file STL e l'immagine del modello pertinente nei campi forniti.`,
        images: images.step7,
      },
      {
        text: `Dopo il caricamento, puoi accedere alla scena del modello selezionando il modello 3D desiderato. Puoi prendere misure sui modelli, passare alle viste sezionali e lasciare note sui punti anatomici rilevanti.`,
        images: images.step8,
      },
      {
        text: `Per uscire dalla piattaforma, cliccare sulla scheda "Disconnetti".`,
        images: images.step9,
      },
    ],
  },
  CZ: {
    title:
      "Uživatelská Příručka Evropské Platformy Anatomického Modelování Pro Mládež",
    downloadUrl:
      "https://firebasestorage.googleapis.com/v0/b/eyou3damp.appspot.com/o/assessmentQuestionnaire%2FassessmentQuestionnaire_CZ.docx?alt=media&token=9ba2cbe5-4a10-453b-9c7b-d8d476958f56",
    downloadText: "Stáhněte si hodnotící dotazník",
    steps: [
      {
        text: "Do webového prohlížeče zadejte 'https://eyou3damp.org/'.",
        images: images.step1,
      },
      {
        text: `Otevře se webová stránka platformy. Informace o projektu naleznete na příslušných záložkách. Pro přístup k platformě klikněte na tlačítko 'Přihlásit se'.`,
        images: images.step2,
      },
      {
        text: `Chcete-li vytvořit nový účet, klikněte na kartu „Zaregistrovat se“. Po dokončení procesu registrace bude na vaši e-mailovou adresu zaslán aktivační odkaz. Dokončete proces aktivace a dokončete registraci.`,
        images: images.step3,
      },
      {
        text: `Po dokončení registrace se přihlaste do platformy pomocí své e-mailové adresy a hesla, které jste si vytvořili.`,
        images: images.step4,
      },
      {
        text: `Po přihlášení se otevře palubní deska platformy.`,
        images: images.step5,
      },
      {
        text: `Přejděte na stránku "3D modely". Zde můžete procházet modely nahrané členy platformy.`,
        images: images.step6,
      },
      {
        text: `Chcete-li nahrát svůj vlastní soubor STL 3D anatomického modelu, vyberte záložku "Nahrát". Poté nahrajte svůj soubor STL a příslušný obrázek modelu do poskytnutých polí.`,
        images: images.step7,
      },
      {
        text: `Po nahrání můžete přistupovat ke scéně modelu výběrem požadovaného 3D modelu. Můžete provádět měření na modelech, přepínat na řezy a zanechávat poznámky k relevantním anatomickým bodům.`,
        images: images.step8,
      },
      {
        text: `Chcete-li se odhlásit z platformy, klikněte na záložku "Odhlásit se".`,
        images: images.step9,
      },
    ],
  },
};

const ELearningsText = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [language, setLanguage] = useState("EN");
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const languages = ["EN", "TR", "IT", "CZ"];
  const flags = {
    EN: en,
    TR: tr,
    CZ: cz,
    IT: it,
  };

  const [activeTab, setActiveTab] = useState("guide");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // Animation for fading in the content
    gsap.from(contentRef.current, {
      opacity: 0,
      y: 30,
      duration: 1,
      delay: 0.5,
    });

    // Function to handle scrolling and toggle the scroll-to-top button visibility
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300; // Adjust the threshold value to control when the button appears
      setShowScrollToTop(scrollY > threshold);
    };

    // Add event listener to handle scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]);

  const openDropdown = () => {
    setIsDropdownOpen(true);
    gsap.fromTo(
      ".language-option",
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
    );
  };

  const closeDropdown = () => {
    gsap.to(".language-option", {
      x: -50,
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
      onComplete: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const handleLanguageChange = (lang) => {
    closeDropdown();
    gsap.to(dropdownRef.current, {
      onComplete: () => {
        setLanguage(lang);
        gsap.fromTo(
          contentRef.current,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 1 }
        );
      },
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDownloadButtonClick = (downloadURL, filename) => {
    // Open the URL in a new tab
    window.open(downloadURL, "_blank");
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-blue-950 flex flex-col items-center justify-center fade-in">
      <div className="min-h-screen max-w-6xl py-20 px-32 bg-slate-50">
        {/* Sekmeler */}
        <div className="flex justify-center mb-6">
          <div className="inline-flex border border-gray-300 rounded-md overflow-hidden">
            <button
              onClick={() => handleTabChange("guide")}
              className={`py-2 px-4 ${
                activeTab === "guide"
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-600"
              } ${activeTab !== "guide" && "border-r border-gray-300"}`}
            >
              User Guide
            </button>
            <button
              onClick={() => handleTabChange("pdf")}
              className={`py-2 px-4 ${
                activeTab === "pdf"
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-600"
              }`}
            >
              3D PDF Files
            </button>
          </div>
        </div>
        {/* Language Dropdown - Sadece "User Guide" sekmesinde */}
        {activeTab === "guide" && (
          <div
            className="relative inline-block text-left mb-4"
            ref={dropdownRef}
          >
            <div className="flex items-center">
              <button
                onClick={() =>
                  isDropdownOpen ? closeDropdown() : openDropdown()
                }
                className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                <span className="mr-2">
                  <img
                    src={flags[language]}
                    alt={language}
                    className="h-5 w-7 inline-block"
                  />
                </span>
                {language}
              </button>
              <div
                className={`flex items-center ${
                  isDropdownOpen ? "ml-2" : "hidden"
                }`}
              >
                {languages
                  .filter((lang) => lang !== language)
                  .map((lang) => (
                    <button
                      key={lang}
                      onClick={() => handleLanguageChange(lang)}
                      className="language-option flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <img
                        src={flags[lang]}
                        alt={lang}
                        className="h-5 w-7 mr-2"
                      />
                      {lang}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        )}
        {/* User Guide İçeriği */}
        {activeTab === "guide" && (
          <div ref={contentRef}>
            <h2 className="text-4xl font-extrabold text-center text-blue-900 mb-10">
              {content[language].title}
            </h2>
            <div className="bg-white shadow-md rounded-lg overflow-hidden p-6">
              {content[language].steps.map((step, index) => (
                <div key={index} className="mb-8">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-3">
                    {index + 1}. {step.text}
                  </h3>
                  <div className="flex flex-wrap justify-center items-center space-x-4">
                    {step.images.map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={image.src}
                        alt={`Step ${index + 1} - ${imgIndex + 1}`}
                        className={`rounded-lg mb-4 ${image.className}`} // Apply custom className
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* Download Button */}
            <div className="text-center mt-10">
              <a
                href={content[language].downloadUrl}
                download
                className="inline-block px-6 py-3 bg-blue-600 text-white font-medium text-lg rounded-md shadow hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                {content[language].downloadText}
              </a>
            </div>
          </div>
        )}
        {/* PDF Files İçeriği */}
        {activeTab === "pdf" && (
          <div ref={contentRef}>
            <h2 className="text-2xl font-bold text-center text-blue-900 mb-10 w-2/3 mx-auto">
              PLEASE OPEN THESE 3D PDF FILES WITH CURRENT VERSION OF ADOBE
              ACROBAT PRO
            </h2>
            <div className="grid grid-cols-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {pdfImages.map((image, index) => (
                <div
                  key={index}
                  className="bg-white p-2 border rounded-md shadow-sm flex flex-col items-center"
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-32 h-32 object-cover mb-2"
                  />
                  <div className="flex justify-between w-full text-sm text-gray-700 mb-2">
                    <span className="text-left ml-2">
                      {image.alt.replace(/_/g, " ")}
                    </span>
                    <button
                      onClick={() =>
                        handleDownloadButtonClick(image.downloadURL, image.alt)
                      }
                      className="px-4 hover:text-cyan-500 hover:motion-safe:animate-bounce"
                    >
                      <FileDownloadOutlinedIcon />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Scroll to Top Button */}
        {showScrollToTop && (
          <button
            className="fixed bottom-8 right-8 bg-slate-50 hover:text-blue-700 px-3 py-2 rounded fade-in"
            onClick={scrollToTop}
          >
            <ArrowUpwardRoundedIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default ELearningsText;
