import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import WhatIs from "../components/WhatIs";

const About = () => {
  return (
    <>
      <Navbar />
      <WhatIs />
      <LogoBar />
      <Footer />
    </>
  );
};

export default About;
