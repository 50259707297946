import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import AnnotationsSidebar from "./AnnotationsSidebar";
import AnnotationModal from "./AnnotationModal";
import TWEEN from "@tweenjs/tween.js";
import {
  getDatabase,
  ref,
  push,
  update,
  child,
  remove,
} from "firebase/database";

const ModelViewer = ({ stlURL, modelColor, itemId, annotations }) => {
  const containerRef = useRef(null);
  const modelRef = useRef(null);
  const controlsRef = useRef(null);
  const cameraRef = useRef(null);
  const sceneRef = useRef(null);
  const currentTweenRef = useRef(null);
  const color = useMemo(() => new THREE.Color(modelColor), [modelColor]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(0);
  const [isAnnotationMode, setIsAnnotationMode] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [pins, setPins] = useState(
    annotations.map((pin) => ({ ...pin, show: true }))
  );
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [tempAnnotationDetails, setTempAnnotationDetails] = useState(null);
  const [isClippingEnabled, setIsClippingEnabled] = useState(false);
  /*   const [clippingPlaneNormal, setClippingPlaneNormal] = useState(
    new THREE.Vector3(1, 0, 0)
  ); */
  const [clippingPlaneConstant, setClippingPlaneConstant] = useState(0);
  const [sliderRange, setSliderRange] = useState({ min: -10, max: 10 });
  const [isDistanceMeasuringMode, setIsDistanceMeasuringMode] = useState(false);
  const [pathPoints, setPathPoints] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [theta, setTheta] = useState(0);
  const [phi, setPhi] = useState(Math.PI / 2);

  const addPoint = useCallback(
    (event) => {
      const rect = containerRef.current.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      const intersects = raycaster.intersectObject(modelRef.current, true);

      if (intersects.length > 0) {
        const intersectPoint = intersects[0].point;

        const geometry = new THREE.SphereGeometry(1, 32, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.copy(intersectPoint);
        sceneRef.current.add(sphere);

        setPathPoints((prevPoints) => {
          const updatedPoints = [...prevPoints, sphere];
          calculateAndShowDistance(updatedPoints);
          return updatedPoints;
        });
      }
    },
    [cameraRef, modelRef, sceneRef, setPathPoints]
  );

  const calculateOptimalCameraPosition = (annotationPosition) => {
    const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);

    // Calculate the distance from the annotation based on the model size
    const distance = maxDimension;
    const height = maxDimension;

    // Calculate new camera position
    const direction = new THREE.Vector3()
      .subVectors(annotationPosition, center)
      .normalize();
    const newPosition = new THREE.Vector3().addVectors(
      annotationPosition,
      direction.multiplyScalar(distance)
    );
    newPosition.y += height; // Adjust height to look from above

    return newPosition;
  };

  function moveToAnnotation(annotationPosition) {
    if (!sceneRef.current || !modelRef.current) {
      console.error("Scene or model is not ready.");
      return;
    }

    if (currentTweenRef.current) {
      currentTweenRef.current.stop();
    }

    // Calculate optimal positions for both the camera and its target based on the annotation.
    const optimalCameraPosition =
      calculateOptimalCameraPosition(annotationPosition);
    const cameraTargetPosition = annotationPosition; // Directly focusing on the annotation.

    const initialCameraPosition = {
      x: cameraRef.current.position.x,
      y: cameraRef.current.position.y,
      z: cameraRef.current.position.z,
    };

    const initialTargetPosition = {
      x: controlsRef.current.target.x,
      y: controlsRef.current.target.y,
      z: controlsRef.current.target.z,
    };

    // Interpolating camera position
    const cameraTween = new TWEEN.Tween(initialCameraPosition)
      .to(
        {
          x: optimalCameraPosition.x,
          y: optimalCameraPosition.y,
          z: optimalCameraPosition.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(({ x, y, z }) => cameraRef.current.position.set(x, y, z))
      .start();

    // Interpolating camera target
    const targetTween = new TWEEN.Tween(initialTargetPosition)
      .to(
        {
          x: cameraTargetPosition.x,
          y: cameraTargetPosition.y,
          z: cameraTargetPosition.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(({ x, y, z }) => controlsRef.current.target.set(x, y, z))
      .onComplete(() => {
        controlsRef.current.update();
        currentTweenRef.current = null; // Ensure to clear the reference once animation completes
      })
      .start();

    // Keep a reference to the current tween for potential stopping
    currentTweenRef.current = { cameraTween, targetTween };
  }

  /*   useEffect(() => {
    if (modelRef.current) {
      const plane = new THREE.Plane(clippingPlaneNormal, clippingPlaneConstant);
      modelRef.current.material.clippingPlanes = [plane];
      modelRef.current.material.needsUpdate = true;
    }
  }, [clippingPlaneNormal, clippingPlaneConstant]); */

  useEffect(() => {
    const loadModel = () => {
      const loader = new STLLoader();
      loader.load(
        stlURL,
        (geometry) => {
          // Create the scene
          const scene = new THREE.Scene();
          sceneRef.current = scene; // Store the scene reference

          // Create a camera
          const camera = new THREE.PerspectiveCamera(
            50,
            containerRef.current.clientWidth /
              containerRef.current.clientHeight,
            0.1,
            10000
          );
          cameraRef.current = camera; // Store the camera reference

          // Create a renderer
          const renderer = new THREE.WebGLRenderer({ alpha: true });
          renderer.setSize(
            containerRef.current.clientWidth,
            containerRef.current.clientHeight
          );
          renderer.localClippingEnabled = true;
          renderer.setClearColor(0xdddddd);
          containerRef.current.appendChild(renderer.domElement);

          // Create controls
          const controls = new TrackballControls(
            cameraRef.current,
            renderer.domElement
          );
          controlsRef.current = controls;
          controls.rotateSpeed = 5.0;
          controls.zoomSpeed = 2;
          controls.panSpeed = 0.8;
          controls.staticMoving = true;
          controls.dynamicDampingFactor = 0.3;

          // Create the mesh
          const material = new THREE.MeshPhongMaterial({
            color: color,
            specular: 0x888888,
            shininess: 20,
            transparent: true,
            opacity: 1,
            clippingPlanes: [],
            clipShadows: true,
            side: THREE.DoubleSide,
          });
          const mesh = new THREE.Mesh(geometry, material);

          // Calculate the bounding box of the model
          const boundingBox = new THREE.Box3().setFromObject(mesh);

          //space center calculations
          const spaceCenter = new THREE.Vector3();
          boundingBox.getCenter(spaceCenter);
          // Move the mesh to the center of the scene
          mesh.position.x -= spaceCenter.x;
          mesh.position.y -= spaceCenter.y;
          mesh.position.z -= spaceCenter.z;

          scene.add(mesh);
          modelRef.current = mesh;

          const newCenter = new THREE.Vector3(0, 0, 0);
          const size = boundingBox.getSize(new THREE.Vector3());

          // Determine a suitable camera distance based on the dimensions of the model
          const modelWidth = size.x;
          const modelHeight = size.y;
          const modelDepth = size.z;

          // Determine the maximum dimension of the model
          const maxDimension = Math.max(modelWidth, modelHeight, modelDepth);

          setSliderRange({ min: -maxDimension, max: maxDimension });

          // Set the camera distance relative to the maximum dimension
          const distance = maxDimension * 3;

          camera.position.set(newCenter.x, newCenter.y, newCenter.z + distance);
          controls.target.copy(newCenter);

          // Create ground
          const groundRadius = maxDimension * 1.5;
          const groundSegments = 128;

          const groundGeometry = new THREE.CircleGeometry(
            groundRadius,
            groundSegments
          );

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const gradient = context.createRadialGradient(
            100,
            100,
            0,
            100,
            100,
            100
          );
          gradient.addColorStop(0, "#999999");
          gradient.addColorStop(1, "#eeeeee");
          context.fillStyle = gradient;
          context.fillRect(0, 0, 300, 300);

          const texture = new THREE.Texture(canvas);
          texture.needsUpdate = true;
          const groundMaterial = new THREE.MeshPhongMaterial({
            map: texture,
            shininess: 0,
          });

          const ground = new THREE.Mesh(groundGeometry, groundMaterial);
          ground.position.set(
            newCenter.x,
            newCenter.y,
            newCenter.z - modelDepth / 2 - 10
          );
          scene.add(ground);

          // lights
          const light1 = new THREE.DirectionalLight(0xffffff, 0.7);
          light1.position.set(1, 1, 1);
          scene.add(light1);

          const light2 = new THREE.DirectionalLight(0xffffff, 0.7);
          light2.position.set(-1, -1, -1);
          scene.add(light2);

          const light3 = new THREE.DirectionalLight(0xffffff, 0.7);
          light3.position.set(-1, 1, 1);
          scene.add(light3);

          const light4 = new THREE.DirectionalLight(0xffffff, 0.7);
          light4.position.set(1, -1, -1);
          scene.add(light4);

          // Render the scene
          function animate() {
            requestAnimationFrame(animate);
            TWEEN.update();
            controls.update();
            renderer.render(sceneRef.current, cameraRef.current);
          }

          animate();

          // Update the renderer size when the window is resized
          const handleResize = () => {
            if (containerRef.current) {
              renderer.setSize(
                containerRef.current.clientWidth,
                containerRef.current.clientHeight
              );
              camera.aspect =
                containerRef.current.clientWidth /
                containerRef.current.clientHeight;
              camera.updateProjectionMatrix();
            }
          };
          window.addEventListener("resize", handleResize);

          // Clean up event listeners on component unmount
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        },
        (xhr) => {
          const progress = (xhr.loaded / xhr.total) * 100;
          setLoadProgress(progress);

          if (progress === 100) {
            setIsLoading(false);
          }
        }
      );
    };

    loadModel();
  }, [color, stlURL]);

  useEffect(() => {
    const normalX = Math.sin(phi) * Math.cos(theta);
    const normalY = Math.cos(phi);
    const normalZ = Math.sin(phi) * Math.sin(theta);
    const newNormal = new THREE.Vector3(normalX, normalY, normalZ);

    if (!sceneRef.current || !modelRef.current) return;

    const plane = new THREE.Plane(newNormal, clippingPlaneConstant);

    modelRef.current.material.clippingPlanes = isClippingEnabled ? [plane] : [];
    modelRef.current.material.needsUpdate = true;

    let helper = sceneRef.current.getObjectByName("clippingPlaneHelper");
    if (isClippingEnabled) {
      if (!helper) {
        helper = new THREE.PlaneHelper(plane, 50, 0xff0000);
        helper.name = "clippingPlaneHelper";
        sceneRef.current.add(helper);
      } else {
        helper.plane.copy(plane);
      }
      helper.visible = true;
    } else if (helper) {
      helper.visible = false;
    }

    const controls = controlsRef.current;
    if (controls) {
      controls.update();
    }
  }, [theta, phi, clippingPlaneConstant, isClippingEnabled]);

  useEffect(() => {
    const currentContainer = containerRef.current;

    let clickStartPos = { x: null, y: null };

    const onMouseDown = (event) => {
      clickStartPos = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (event) => {
      const clickEndPos = { x: event.clientX, y: event.clientY };
      const dx = clickEndPos.x - clickStartPos.x;
      const dy = clickEndPos.y - clickStartPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      const controlBarHeight = 50;
      const clickWithinControlBar =
        window.innerHeight - event.clientY <= controlBarHeight;

      if (distance < 5 && isAnnotationMode && !clickWithinControlBar) {
        handleAnnotationClick(event);
      }
    };

    const handleAnnotationClick = (event) => {
      if (tempAnnotationDetails) return;

      event.preventDefault();

      const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, cameraRef.current);
      const intersects = raycaster.intersectObject(modelRef.current, true);

      if (intersects.length > 0) {
        const point = intersects[0].point;
        setTempAnnotationDetails({ x: point.x, y: point.y, z: point.z });
        setIsAnnotationModalOpen(true);
      }
    };

    if (currentContainer) {
      currentContainer.addEventListener("mousedown", onMouseDown);
      currentContainer.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("mousedown", onMouseDown);
        currentContainer.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, [isAnnotationMode, tempAnnotationDetails]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isNowFullscreen = !!document.fullscreenElement;
      setIsFullscreen(isNowFullscreen);

      if (!isNowFullscreen) {
        setIsAnnotationMode(false);
        setIsDistanceMeasuringMode(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    let clickStartPos = { x: null, y: null };

    const onMouseDown = (event) => {
      clickStartPos = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (event) => {
      const clickEndPos = { x: event.clientX, y: event.clientY };
      const dx = clickEndPos.x - clickStartPos.x;
      const dy = clickEndPos.y - clickStartPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      const controlBarHeight = 150; // Adjust based on your actual control bar height
      const clickWithinControlBar =
        window.innerHeight - event.clientY <= controlBarHeight;

      if (distance < 5) {
        if (
          isDistanceMeasuringMode &&
          !clickWithinControlBar &&
          isDistanceMeasuringMode
        ) {
          addPoint(event);
        }
      }
    };

    // Attach event listeners
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [addPoint, isDistanceMeasuringMode]);

  useEffect(() => {
    const currentAnnotations = pins;

    const timeoutId = setTimeout(() => {
      const newPins = currentAnnotations.map((annotation) => {
        const position = new THREE.Vector3(
          annotation.coordinates.x,
          annotation.coordinates.y,
          annotation.coordinates.z
        );

        const pinMesh = createPin(position);
        return { ...annotation, mesh: pinMesh };
      });
      setPins(newPins);
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveAnnotation = (annotationText, initialPosition, itemId) => {
    if (tempAnnotationDetails) {
      const pinMesh = createPin(tempAnnotationDetails);

      const db = getDatabase();
      const newPinId = push(child(ref(db), `/items/${itemId}/annotations`)).key;

      const newPin = {
        coordinates: tempAnnotationDetails,
        annotation: annotationText,
      };

      update(ref(db, `/items/${itemId}/annotations/${newPinId}`), newPin);

      const newPinItem = {
        ...newPin,
        id: newPinId,
        mesh: pinMesh,
      };

      setPins((prevPins) => [...prevPins, newPinItem]);
      setIsAnnotationModalOpen(false);
      /* setIsAnnotationMode(false); */
      setTempAnnotationDetails(null);
    }
  };

  const handleDeleteAnnotation = (index, itemId, annotationId) => {
    const targetPin = pins[index];

    if (targetPin && targetPin.mesh) {
      targetPin.mesh.geometry.dispose();
      targetPin.mesh.material.dispose();
      sceneRef.current.remove(targetPin.mesh);
    }

    const updatedPins = [...pins];
    updatedPins.splice(index, 1);
    setPins(updatedPins);

    const db = getDatabase();
    const annotationRef = ref(
      db,
      `/items/${itemId}/annotations/${annotationId}`
    );

    remove(annotationRef);
  };

  const handleCancelAnnotation = () => {
    setIsAnnotationModalOpen(false);
    setTempAnnotationDetails(null);
  };

  const handleOpacityChange = (e) => {
    const opacity = parseFloat(e.target.value);
    if (modelRef.current) {
      modelRef.current.material.opacity = opacity;
      modelRef.current.material.needsUpdate = true;
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const createPin = (position) => {
    if (!sceneRef.current) {
      setTimeout(() => {
        createPin(position);
      }, 1000);
      return null;
    }

    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const pin = new THREE.Mesh(geometry, material);

    pin.position.set(position.x, position.y, position.z);
    sceneRef.current.add(pin);

    return pin; // Return the pin if creation was successful
  };

  const clearMeasurements = () => {
    pathPoints.forEach((sphere) => {
      sceneRef.current.remove(sphere);
      sphere.geometry.dispose();
      sphere.material.dispose();
    });

    setPathPoints([]);
    setTotalDistance(0);
  };

  const calculateAndShowDistance = (points) => {
    let total = 0;
    for (let i = 0; i < points.length - 1; i++) {
      const pointA = points[i].position;
      const pointB = points[i + 1].position;
      const distance = pointA.distanceTo(pointB);
      total += distance;
    }
    setTotalDistance(total.toFixed(2)); // Ensure this state update is reflected in the component
  };

  const toggleAnnotationMode = () => {
    if (isFullscreen) {
      if (!isAnnotationMode && isDistanceMeasuringMode) {
        setIsDistanceMeasuringMode(false);
        setIsAnnotationMode(true);
      } else {
        setIsAnnotationMode(!isAnnotationMode);
      }
    } else {
      toggleFullScreen();
      if (!isAnnotationMode && isDistanceMeasuringMode) {
        setIsDistanceMeasuringMode(false);
        setIsAnnotationMode(true);
      } else {
        setIsAnnotationMode(!isAnnotationMode);
      }
    }
  };

  const toggleDistanceMeasuringMode = () => {
    if (isFullscreen) {
      if (isAnnotationMode && !isDistanceMeasuringMode) {
        setIsDistanceMeasuringMode(true);
        setIsAnnotationMode(false);
      } else {
        setIsDistanceMeasuringMode(!isDistanceMeasuringMode);
      }
    } else {
      toggleFullScreen();
      if (isAnnotationMode && !isDistanceMeasuringMode) {
        setIsDistanceMeasuringMode(true);
        setIsAnnotationMode(false);
      } else {
        setIsDistanceMeasuringMode(!isDistanceMeasuringMode);
      }
    }
  };

  useEffect(() => {
    const updatePinsPosition = () => {
      pins.forEach((pin, index) => {
        // Get the position of the pin in world coordinates
        const worldPosition = new THREE.Vector3(
          pin.coordinates.x,
          pin.coordinates.y,
          pin.coordinates.z
        );

        // Project the world coordinates to screen coordinates
        const screenPosition = worldPosition.project(cameraRef.current);

        // Convert screen coordinates to CSS pixels
        const x =
          ((screenPosition.x + 1) / 2) * containerRef.current.clientWidth;
        const y =
          ((-screenPosition.y + 1) / 2) * containerRef.current.clientHeight;

        // Update the position of the pin if the element exists
        const pinElement = document.getElementById(`pin-${index}`);
        if (pinElement) {
          pinElement.style.top = `${y}px`;
          pinElement.style.left = `${x}px`;
        }
      });
    };

    if (containerRef.current && cameraRef.current && controlsRef.current) {
      updatePinsPosition();

      // Add event listeners for changes in viewport size or camera movement
      window.addEventListener("resize", updatePinsPosition);
      controlsRef.current.addEventListener("change", updatePinsPosition);

      return () => {
        // Cleanup: remove event listeners
        window.removeEventListener("resize", updatePinsPosition);
        controlsRef.current.removeEventListener("change", updatePinsPosition);
      };
    }
  }, [pins]);

  const renderPins = () => {
    return pins.map((pin, index) => {
      if (!pin.show) {
        // If pin's show value is false, return null to skip rendering
        return null;
      }

      // Get the position of the pin in world coordinates
      const worldPosition = new THREE.Vector3(
        pin.coordinates.x,
        pin.coordinates.y,
        pin.coordinates.z
      );

      // Project the world coordinates to screen coordinates
      const screenPosition = worldPosition.project(cameraRef.current);

      // Convert screen coordinates to CSS pixels
      const x = ((screenPosition.x + 1) / 2) * containerRef.current.clientWidth;
      const y =
        ((-screenPosition.y + 1) / 2) * containerRef.current.clientHeight;

      return (
        <div
          key={index}
          id={`pin-${index}`}
          className="absolute"
          style={{
            top: y + "px",
            left: x + "px",
          }}
        >
          <div className="relative bg-white border border-gray-300 rounded p-2">
            {/* Conversation balloon shape */}
            <div
              className="absolute bg-white w-3 h-3"
              style={{
                top: "-1.5px",
                left: "-1.5px",
                zIndex: "-1",
                transform: "rotate(45deg)",
                borderTopRightRadius: "3px",
                border: "1px solid #d1d5db",
                borderLeft: "none",
                borderBottom: "none",
              }}
            ></div>
            <p className="text-sm font-medium">{pin.annotation}</p>
          </div>
        </div>
      );
    });
  };

  const handleCheckboxChange = (checked, id) => {
    setPins((prevPins) => {
      return prevPins.map((pin) => {
        if (pin.id === id) {
          return { ...pin, show: checked };
        }
        return pin;
      });
    });
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <p className="text-lg font-semibold text-indigo-600">
            Loading... {loadProgress.toFixed(2)}%
          </p>
        </div>
      ) : (
        <div className="relative h-screen overflow-hidden bg-gray-50">
          <div className="w-full h-full" ref={containerRef}>
            {containerRef.current && renderPins()}
            {/* Annotation Sidebar and Modal */}
            <AnnotationsSidebar
              pins={pins}
              distance={totalDistance}
              onDelete={handleDeleteAnnotation}
              handleCheckboxChange={handleCheckboxChange}
              itemId={itemId}
              onSelect={(pin) => {
                moveToAnnotation(
                  new THREE.Vector3(
                    pin.coordinates.x,
                    pin.coordinates.y,
                    pin.coordinates.z
                  )
                );
              }}
            />
            <AnnotationModal
              isOpen={isAnnotationModalOpen}
              onSave={handleSaveAnnotation}
              onCancel={handleCancelAnnotation}
              initialPosition={tempAnnotationDetails}
              itemId={itemId}
            />
            {/* Controls Container */}
            <div className="absolute bottom-0 left-0 w-full p-2 bg-black bg-opacity-50 text-white flex justify-center items-end space-x-4 z-50 text-sm">
              {/* Measure Distance and its options */}
              <div className="relative flex flex-col items-center space-y-2">
                {isDistanceMeasuringMode && (
                  <div className="absolute bottom-full mb-2 flex flex-col items-center space-y-2 bg-black bg-opacity-50 p-2 rounded">
                    {/* <button
                      onClick={calculateAndShowDistance}
                      className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                    >
                      Show Distance
                    </button> */}
                    <button
                      onClick={clearMeasurements}
                      className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    >
                      Clear Points
                    </button>
                  </div>
                )}
                <button
                  onClick={() => toggleDistanceMeasuringMode()}
                  className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  {isDistanceMeasuringMode
                    ? "Exit Distance Measure"
                    : "Measure Distance"}
                </button>
              </div>
              {/* Section View Value sliders */}
              <div className="relative flex flex-col items-center space-y-2">
                {isClippingEnabled && (
                  <div className="absolute bottom-full mb-2 flex flex-col items-center space-y-2 bg-black bg-opacity-50 p-2 rounded">
                    {/* Predefined Theta Buttons */}
                    <div className="flex space-x-1 mb-2">
                      <button
                        onClick={() => setTheta(0)}
                        className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                      >
                        0
                      </button>
                      <button
                        onClick={() => setTheta(Math.PI / 2)}
                        className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                      >
                        π/2
                      </button>
                      <button
                        onClick={() => setTheta(Math.PI)}
                        className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                      >
                        π
                      </button>
                      <button
                        onClick={() => setTheta((3 * Math.PI) / 2)}
                        className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                      >
                        3π/2
                      </button>
                    </div>
                    <div className="flex items-center space-x-1">
                      <span>Theta:</span>
                      <input
                        type="range"
                        min={-Math.PI}
                        max={Math.PI}
                        step="0.01"
                        value={theta}
                        onChange={(e) => setTheta(parseFloat(e.target.value))}
                        className="w-24 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center space-x-1">
                      <span>Phi:</span>
                      <input
                        type="range"
                        min={-Math.PI / 2}
                        max={Math.PI / 2}
                        step="0.01"
                        value={phi}
                        onChange={(e) => setPhi(parseFloat(e.target.value))}
                        className="w-24 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                    </div>
                    <div className="flex flex-col items-center space-y-1">
                      <div className="flex items-center space-x-1">
                        <span>Normal:</span>
                        <input
                          type="range"
                          min={sliderRange.min}
                          max={sliderRange.max}
                          step="0.01"
                          value={clippingPlaneConstant}
                          onChange={(e) =>
                            setClippingPlaneConstant(parseFloat(e.target.value))
                          }
                          className="w-24 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        />
                      </div>
                      <button
                        onClick={() => setClippingPlaneConstant(0)}
                        className="px-2 py-1 mt-2 text-xs text-white bg-blue-800 rounded hover:bg-blue-600"
                      >
                        Reset Normal
                      </button>
                    </div>
                  </div>
                )}

                <button
                  onClick={() => setIsClippingEnabled(!isClippingEnabled)}
                  className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  {isClippingEnabled
                    ? "Disable Section View"
                    : "Enable Section View"}
                </button>
              </div>

              {/* Independent Controls */}
              <button
                onClick={() => toggleAnnotationMode()}
                className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {isAnnotationMode
                  ? "Disable Annotations"
                  : "Enable Annotations"}
              </button>
              <div className="flex items-center space-x-2 bg-gray 700 hover:bg-gray-800 bg-opacity-75 p-1 rounded-lg">
                <label htmlFor="opacity-control">Opacity:</label>
                <input
                  id="opacity-control"
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  defaultValue="1"
                  onChange={handleOpacityChange}
                  className="w-32 h-2 bg-blue-700 rounded-lg appearance-none cursor-pointer transition-opacity duration-200 ease-in-out"
                  title="Adjust model opacity"
                />
              </div>
              <button
                onClick={toggleFullScreen}
                className="py-1 px-2 hover:bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelViewer;
