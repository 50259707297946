import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import UserDropdown from "./UserDropdown";

/* import logo from "./images/logo.png";
import Badge from "@mui/material/Badge";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"; */

import HomeIcon from "@mui/icons-material/Home";

const Navbar = () => {
  const navigate = useNavigate();
  /*  const quantity = useSelector((state) => state.cart.quantity); */
  const isSignedIn = useSelector((state) => state.user.isSignedIn);

  return (
    <div className="relative">
      <nav className="bg-opacity-100 bg-blue-800">
        <div>
          <div className="flex items-center justify-between h-16 px-16">
            {/* Home */}
            <button
              className="px-4 py-2 rounded-md shadow text-slate-50 hover:text-sky-400"
              onClick={() => navigate("/home")}
            >
              <HomeIcon />
            </button>
            {/* Header with Logo */}
            <div className="flex justify-center items-center space-x-2 text-slate-50 text-lg font-semibold">
              <span>EUROPEAN YOUTH 3D ANATOMICAL MODELING PLATFORM</span>
            </div>
            {/* Sign In, Login, Cart */}
            <div className="flex items-center space-x-4">
              {isSignedIn ? (
                <UserDropdown />
              ) : (
                <>
                  <button
                    className="px-4 py-2 rounded-md text-sm font-medium shadow hover:text-sky-400 text-slate-200"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </button>
                  <button
                    className="px-4 py-2 rounded-md text-sm font-medium shadow hover:text-sky-400 text-slate-200"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </button>
                </>
              )}
              {/* <Link
                to="/cart"
                className="px-4 py-2 rounded-md text-sm font-medium shadow hover:text-green-500 text-slate-200"
              >
                <Badge badgeContent={quantity} max={99} color="primary">
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </Link> */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
