import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeModel } from "../redux/cartRedux";
import DeleteIcon from "@mui/icons-material/Delete";
/* import { child, push, ref, set } from "firebase/database";
import { database } from "../firebase"; */
import FavoriteIcon from "@mui/icons-material/Favorite";

const CartDetails = () => {
  const dispatch = useDispatch();
  /* const navigate = useNavigate(); */
  /* const currentUid = useSelector((state) => state.user.currentUid); */
  const user = useSelector((state) => state.user.currentUser);
  const cart = useSelector((state) => state.cart);
  const { models, quantity } = cart;

  /* const handleClearCart = () => {
    dispatch(clearCart());
  }; */

  const handleRemoveItemFromCart = (itemId) => {
    const cartItemToRemove = models.find((item) => item.id === itemId);
    dispatch(removeModel({ ...cartItemToRemove }));
  };

  /* const handleCheckOut = (currentUid) => {
    models.forEach((model) => {
      const newKey = push(
        child(ref(database), "/users/" + currentUid + "/boughtModels")
      ).key;

      set(
        ref(database, "/users/" + currentUid + "/boughtModels/" + newKey),
        model.id
      );
      set(
        ref(database, "/items/" + model.id + "/sharedUsers/" + newKey),
        currentUid
      );
    });

    // clear cart & send user somewhere
    handleClearCart();
    navigate("/dashboard");
  }; */

  return (
    <div className="container mx-auto p-8">
      {/* First Row */}
      <div className="flex items-center justify-center mb-6">
        <h2 className="text-2xl font-medium text-gray-700">Your Cart</h2>
        <div className="flex items-center justify-center ml-4 h-8 w-8 bg-blue-700 text-green-400 font-medium rounded-full">
          <span>{quantity}</span>
        </div>
      </div>
      {/* Second Row */}
      <div className="flex items-center justify-between mb-6">
        <button className="h-12 bg-blue-800 hover:bg-blue-900 hover:text-green-400 text-slate-50 font-semibold py-2 px-4 rounded-md shadow-md">
          <Link to="/dashboard">Continue Shopping</Link>
        </button>

        <button className="flex items-center h-12 bg-blue-800 hover:bg-blue-900 hover:text-red-500 text-slate-50 font-semibold py-2 px-4 rounded-md shadow-md">
          My Favorites ({user.wishlist.length})
          <div className="mx-1 text-red-500">
            <FavoriteIcon />
          </div>
        </button>
      </div>

      {/* Third Row */}
      <div className="flex">
        {/* Left Column - Item List */}
        <div className="w-full">
          <ul>
            {models.map((item) => (
              <li
                key={item.id}
                className="mb-2 pb-2 border-b hover:border-blue-200 shadow hover:shadow-md hover:shadow-blue-200 bg-blue-100/50 hover:bg-blue-100/75"
              >
                <div className="flex items-center py-2">
                  <div className="w-36 h-36 mx-4">
                    <img
                      src={item.imgURL}
                      alt={item.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="mx-6">
                    <h4 className="text-sm text-slate-400">{item.category}</h4>
                    <p className="text-lg text-gray-700">{item.title}</p>
                  </div>
                  <div className="flex-grow"></div>
                  {/* <div className="mx-6 py-6 pl-6 text-right font-semibold text-lg hover:text-green-600">
                    <p>${item.price}</p>
                  </div> */}
                  <div>
                    <button
                      className="text-gray-400 hover:text-red-600 mx-4"
                      onClick={() => handleRemoveItemFromCart(item.id)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Right Column - Order Summary */}
        {/* <div className="w-1/4 h-72 p-4 border-2 border-blue-100 rounded-md shadow shadow-blue-200 bg-blue-100/50">
          <div className="h-1/5">
            <h2 className="text-2xl font-bold mb-6 pb-4 pt-3 border-b-2 text-center border-blue-800 text-blue-700">
              Order Summary
            </h2>
          </div>
          <div className="flex justify-between h-1/2 p-2 mt-2 mb-4 border-b-2 border-blue-800 text-gray-700">
            <span className="mt-4">Total Price (incl. tax):</span>
            <span className="mt-4 font-semibold hover:text-emerald-500">
              ${total.toFixed(2)}
            </span>
          </div>
          <div className="flex justify-end items-center h-1/5 mt-3">
            <button
              className="mx-1 w-3/4 py-2 bg-green-600 hover:bg-green-700 text-slate-100 font-medium rounded-md"
              onClick={() => handleCheckOut(currentUid)}
              disabled={models.length === 0}
            >
              Pay ${total.toFixed(2)}
            </button>
            <button
              className="mx-1 w-1/4 py-2 bg-red-600 hover:bg-red-700 text-slate-100 font-medium rounded-md"
              onClick={handleClearCart}
              disabled={models.length === 0}
            >
              Clear
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CartDetails;
