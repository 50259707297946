import Navbar from "../components/Navbar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";
import ProjectManagementText from "../components/ProjectManagementText";

const ProjectManagement = () => {
  return (
    <>
      <Navbar />
      <ProjectManagementText />
      <LogoBar />
      <Footer />
    </>
  );
};

export default ProjectManagement;
