import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth, database } from "../firebase";
import { signOutSuccess } from "../redux/userRedux";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../redux/cartRedux";
import { ref, set, push, child } from "firebase/database";

import UploadModal from "./UploadModal";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const UserDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  let timeoutId = null;

  // Modal Related Events
  const name = currentUser?.firstName + " " + currentUser?.lastName;
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleUpload = (modelData) => {
    // Perform the upload logic here
    const createrId = currentUser.id;

    // generate a unique key for model
    const modelKey = push(child(ref(database), `/items`)).key;

    const newModel = {
      ...modelData,
      id: modelKey,
      createrId: createrId,
      sharedUsers: {
        0: "",
      },
    };

    // push it to the database
    set(ref(database, "/items/" + modelKey), newModel);
    set(
      ref(database, "/users/" + createrId + "/createdModels/" + modelKey),
      modelKey
    );
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      dispatch(signOutSuccess());
      dispatch(clearCart());
      navigate("/home");
    } catch (error) {
      // Handle sign out error
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <div
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`flex justify-center items-center hover:text-sky-400 text-slate-200 shadow rounded-md text-sm font-medium cursor-pointer h-10 ${
          isOpen && "rounded-b-none shadow-none"
        }`}
      >
        <div className="ml-2">
          <AccountCircleIcon />
        </div>
        <div className="px-3 py-2">Hi, {currentUser?.firstName}</div>
      </div>
      {isOpen && (
        <div
          className="absolute right-0 origin-top-right z-20 bg-blue-800 rounded rounded-tr-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-options-menu"
          ref={dropdownRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Dropdown items */}
          <div className="py-1 w-32">
            <button
              className="block w-full px-4 py-2 text-sm text-slate-200 hover:text-sky-400 my-3"
              role="menuitem"
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </button>
            <button
              className="block w-full px-4 py-2 text-sm text-slate-200 hover:text-sky-400 mb-3"
              role="menuitem"
              onClick={handleOpenUploadModal}
            >
              Upload
            </button>
            <div className="flex justify-center">
              <button
                className="w-full mx-4 mb-3 px-4 py-2 text-sm font-semibold text-slate-200 bg-red-600 rounded-md hover:bg-red-700"
                role="menuitem"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Render the UploadModal component */}
      {isUploadModalOpen && (
        <UploadModal
          onClose={handleCloseUploadModal}
          onUpload={handleUpload}
          name={name}
        />
      )}
    </div>
  );
};

export default UserDropdown;
